import { gql, useQuery } from '@apollo/client'
import { Fragment } from 'react'
import States from '../componentes/States'
import classnames from 'classnames'

const query = gql`
  query Bitacora {
    categorias {
      nombre
      tareas {
        id
        nombre

        bitacoras {
          fecha
          fechaProyeccion
          hoja
          lavadora
          bomba
        }
      }
    }

    categoriasTunel {
      nombre
      tareas {
        id
        nombre

        bitacoras {
          fecha
          fechaProyeccion
          hoja
          bomba
        }
      }
    }

    categoriaGenerales {
      nombre
      tareas {
        nombre
        bitacoras(limit: 1){
          fecha
          fechaProyeccion
          hoja
        }
      }
    }

    tareasCorrectiva {
      nombre
      bitacoras(limit: 1){
        fecha
        lavadora
        hoja
        bomba
      }
    }

    tareasCorrectivaTunel {
      nombre
      bitacoras(limit: 1){
        fecha
        hoja
        bomba
      }
    }

    staticInfo {
      lavadoras
      bombas
    }
  }
`

export default function General () {
  const { data, loading, error } = useQuery(query)
  if (error) return <States state='error' err={error} />
  if (loading) return <States state='loading' />

  return (
    <>
      <Tabla {...data} />
      <TablaGeneral className='mt-4' {...data} />
      <TablaTunel className='mt-4' {...data} />
    </>
  )
}

function ThRows ({ rows, colSpan }) {
  return (
    <>
      <th scope='col' style={{ backgroundColor: '#acb9d9' }} />
      {rows.map(t => <th style={{ textAlign: 'center', fontSize: '1.2rem' }} colSpan={colSpan} scope='col' key={t.id}>{t.nombre}</th>)}
    </>
  )
}

function Tabla ({ categorias, staticInfo, tareasCorrectiva, className }) {
  const { lavadoras, bombas } = staticInfo
  return (
    <>
      <h2 className='text-center mb-4'>Vista General</h2>
      <div className={classnames('row', className)}>
        <div className='col'>
          <div className='table-responsive' style={{ maxHeight: 'none' }}>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th scope='col' colSpan={2} />

                  {categorias.map(c => (
                    <Fragment key={c.nombre}>
                      <th scope='col' />
                      <th scope='col' colSpan={c.tareas.length * 3} style={{ textAlign: 'center', fontSize: '1.5rem', borderRight: '2px solid #dee2e6', borderLeft: '2px solid #dee2e6' }}>{c.nombre}</th>
                    </Fragment>
                  ))}

                  <th scope='col' />
                  <th scope='col' colSpan={tareasCorrectiva.length * 2} style={{ textAlign: 'center', fontSize: '1.5rem', borderRight: '2px solid #dee2e6', borderLeft: '2px solid #dee2e6' }}>Mantenimiento Correctivo</th>
                </tr>

                <tr>
                  <th scope='col' colSpan={2} />

                  {categorias.map(c => <ThRows key={c.nombre} rows={c.tareas} colSpan={3} />)}
                  <ThRows rows={tareasCorrectiva} colSpan={2} />
                </tr>

                <tr>
                  <th scope='col'>Lavadora</th>
                  <th scope='col'>Bomba</th>

                  {categorias.map(c => (
                    <Fragment key={c.nombre}>
                      <th scope='col' style={{ backgroundColor: '#acb9d9' }} />
                      {c.tareas.map(t => (
                        <Fragment key={t.id}>
                          <th scope='col' style={{ textAlign: 'center' }}>Ultima Fecha</th>
                          <th scope='col' style={{ textAlign: 'center' }}>Hoja Bitacora</th>
                          <th scope='col' style={{ textAlign: 'center' }}>Proxima Fecha</th>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}

                  <th scope='col' style={{ backgroundColor: '#acb9d9' }} />
                  {tareasCorrectiva.map(t => (
                    <Fragment key={t.id}>
                      <th scope='col' style={{ textAlign: 'center' }}>Ultima Fecha</th>
                      <th scope='col' style={{ textAlign: 'center' }}>Hoja Bitacora</th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {lavadoras.map((l, lindex) =>
                  <Fragment key={l}>
                    {bombas.map((b, i) => (
                      <tr key={`l${i}`}>
                        <th scope='row'>{i === 0 && l}</th>
                        <td>{b}</td>

                        {categorias.map(c => (
                          <Fragment key={c.nombre}>
                            <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                            {c.tareas.map(t => {
                              const row = t.bitacoras.find(r => r.bomba === b && r.lavadora === l)
                              return (
                                <Fragment key={t.id}>
                                  {row
                                    ? (
                                      <>
                                        <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                                        <th scope='col'>{row.hoja}</th>
                                        <th scope='col' style={{ borderRightColor: 'black' }}>{row.fechaProyeccion}</th>
                                      </>
                                      )
                                    : (
                                      <>
                                        <th scope='col' />
                                        <th scope='col' />
                                        <th scope='col' style={{ borderRightColor: 'black' }} />
                                      </>
                                      )}
                                </Fragment>
                              )
                            })}
                          </Fragment>
                        ))}

                        <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                        {tareasCorrectiva.map(t => {
                          const row = t.bitacoras.find(r => r.bomba === b && r.lavadora === l)
                          return (
                            <Fragment key={t.id}>
                              {row
                                ? (
                                  <>
                                    <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                                    <th scope='col' style={{ borderRightColor: 'black' }}>{row.hoja}</th>
                                  </>
                                  )
                                : (
                                  <>
                                    <th scope='col' />
                                    <th scope='col' style={{ borderRightColor: 'black' }} />
                                  </>
                                  )}
                            </Fragment>
                          )
                        })}
                      </tr>
                    ))}

                    {lindex < lavadoras.length - 1 && (
                      <tr style={{ backgroundColor: '#acb9d9' }}>
                        <td />
                        <td />

                        {categorias.map(c => (
                          <Fragment key={c.nombre}>
                            <td style={{ borderRightColor: 'black' }} />
                            {c.tareas.map(t =>
                              <Fragment key={t.id}>
                                <th scope='col' style={{ borderLeftColor: 'black' }} />
                                <th scope='col' />
                                <th scope='col' style={{ borderRightColor: 'black' }} />
                              </Fragment>
                            )}
                          </Fragment>
                        ))}

                        <td style={{ borderRightColor: 'black' }} />
                        {tareasCorrectiva.map(t =>
                          <Fragment key={t.id}>
                            <th scope='col' style={{ borderLeftColor: 'black' }} />
                            <th scope='col' style={{ borderRightColor: 'black' }} />
                          </Fragment>
                        )}
                      </tr>
                    )}
                  </Fragment>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

function TablaGeneral ({ categoriaGenerales, className }) {
  return (
    <div className={classnames('row', className)}>
      <div className='col'>
        <div className='table-responsive' style={{ maxHeight: 'none' }}>
          <table className='table table-bordered'>
            <thead>
              <tr>
                {categoriaGenerales.map((c, cIndex, cList) => (
                  <Fragment key={c.nombre}>
                    <th scope='col' colSpan={c.nombre === 'Mantenimiento Correctivo' ? 2 : 3} style={{ textAlign: 'center', fontSize: '1.5rem', borderRight: '2px solid #dee2e6', borderLeft: '2px solid #dee2e6' }}>{c.nombre}</th>
                    <th scope='col' />
                  </Fragment>
                ))}
              </tr>

              <tr>
                {categoriaGenerales.map((c, cIndex, cList) => (
                  <Fragment key={c.nombre}>
                    {c.tareas.map((t, tIndex, tList) => (
                      <Fragment key={t.nombre}>
                        <th scope='col' colSpan={c.nombre === 'Mantenimiento Correctivo' ? 2 : 3} style={{ textAlign: 'center' }}>{t.nombre}</th>
                        {cIndex < cList.length - 1 && tIndex === tList.length - 1 && <th scope='col' style={{ backgroundColor: '#acb9d9' }} />}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </tr>

              <tr>
                {categoriaGenerales.map((c, cIndex, cList) => (
                  <Fragment key={c.nombre}>
                    {c.tareas.map((t, tIndex, tList) => (
                      <Fragment key={t.id}>
                        <th scope='col' style={{ textAlign: 'center' }}>Ultima Fecha</th>
                        <th scope='col' style={{ textAlign: 'center' }}>Hoja Bitacora</th>
                        {c.nombre !== 'Mantenimiento Correctivo' && <th scope='col' style={{ textAlign: 'center' }}>Proxima Fecha</th>}
                        {cIndex < cList.length - 1 && tIndex === tList.length - 1 && <th scope='col' style={{ backgroundColor: '#acb9d9' }} />}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {categoriaGenerales.map((c, cIndex, cList) => (
                  <Fragment key={c.nombre}>
                    {c.tareas.map((t, tIndex, tList) => (
                      <Fragment key={t.nombre}>
                        <td>{t.bitacoras[0].fecha}</td>
                        <td>{t.bitacoras[0].hoja}</td>
                        {c.nombre !== 'Mantenimiento Correctivo' && <td>{t.bitacoras[0].fechaProyeccion}</td>}
                        {cIndex < cList.length - 1 && tIndex === tList.length - 1 && <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </tr>
            </tbody>
            {/* <tbody>
                {lavadoras.map((l, lindex) =>
                  <Fragment key={l}>
                    {bombas.map((b, i) => (
                      <tr key={`l${i}`}>
                        <th scope='row'>{i === 0 && l}</th>
                        <td>{b}</td>

                        {categorias.map(c => (
                          <Fragment key={c.nombre}>
                            <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                            {c.tareas.map(t => {
                              const row = t.bitacoras.find(r => r.bomba === b && r.lavadora === l)
                              return (
                                <Fragment key={t.id}>
                                  {row
                                    ? (
                                      <>
                                        <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                                        <th scope='col'>{row.hoja}</th>
                                        <th scope='col' style={{ borderRightColor: 'black' }}>{row.fechaProyeccion}</th>
                                      </>
                                      )
                                    : (
                                      <>
                                        <th scope='col' />
                                        <th scope='col' />
                                        <th scope='col' style={{ borderRightColor: 'black' }} />
                                      </>
                                      )}
                                </Fragment>
                              )
                            })}
                          </Fragment>
                        ))}

                        <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                        {tareasCorrectiva.map(t => {
                          const row = t.bitacoras.find(r => r.bomba === b && r.lavadora === l)
                          return (
                            <Fragment key={t.id}>
                              {row
                                ? (
                                  <>
                                    <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                                    <th scope='col' style={{ borderRightColor: 'black' }}>{row.hoja}</th>
                                  </>
                                  )
                                : (
                                  <>
                                    <th scope='col' />
                                    <th scope='col' style={{ borderRightColor: 'black' }} />
                                  </>
                                  )}
                            </Fragment>
                          )
                        })}
                      </tr>
                    ))}

                    {lindex < lavadoras.length - 1 && (
                      <tr style={{ backgroundColor: '#acb9d9' }}>
                        <td />
                        <td />

                        {categorias.map(c => (
                          <Fragment key={c.nombre}>
                            <td style={{ borderRightColor: 'black' }} />
                            {c.tareas.map(t =>
                              <Fragment key={t.id}>
                                <th scope='col' style={{ borderLeftColor: 'black' }} />
                                <th scope='col' />
                                <th scope='col' style={{ borderRightColor: 'black' }} />
                              </Fragment>
                            )}
                          </Fragment>
                        ))}

                        <td style={{ borderRightColor: 'black' }} />
                        {tareasCorrectiva.map(t =>
                          <Fragment key={t.id}>
                            <th scope='col' style={{ borderLeftColor: 'black' }} />
                            <th scope='col' style={{ borderRightColor: 'black' }} />
                          </Fragment>
                        )}
                      </tr>
                    )}
                  </Fragment>
                )}
                        </tbody> */}
          </table>
        </div>
      </div>
    </div>
  )
}

function TablaTunel ({ categoriasTunel, staticInfo, className, tareasCorrectivaTunel }) {
  const { bombas } = staticInfo
  return (
    <div className={classnames('row', className)}>
      <div className='col'>
        <div className='table-responsive' style={{ maxHeight: 'none' }}>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th scope='col' colSpan={2} />

                {categoriasTunel.map(c => (
                  <Fragment key={c.nombre}>
                    <th scope='col' />
                    <th scope='col' colSpan={c.tareas.length * 3} style={{ textAlign: 'center', fontSize: '1.5rem', borderRight: '2px solid #dee2e6', borderLeft: '2px solid #dee2e6' }}>{c.nombre}</th>
                  </Fragment>
                ))}

                <th scope='col' />
                <th scope='col' colSpan={tareasCorrectivaTunel.length * 2} style={{ textAlign: 'center', fontSize: '1.5rem', borderRight: '2px solid #dee2e6', borderLeft: '2px solid #dee2e6' }}>Mantenimiento Correctivo</th>
              </tr>

              <tr>
                <th scope='col' colSpan={2} />
                {categoriasTunel.map(c => <ThRows key={c.nombre} rows={c.tareas} colSpan={3} />)}
                <ThRows rows={tareasCorrectivaTunel} colSpan={2} />
              </tr>

              <tr>
                <th scope='col'>Lavadora</th>
                <th scope='col'>Bomba</th>

                {categoriasTunel.map(c => (
                  <Fragment key={c.nombre}>
                    <th scope='col' style={{ backgroundColor: '#acb9d9' }} />
                    {c.tareas.map(t => (
                      <Fragment key={t.id}>
                        <th scope='col' style={{ textAlign: 'center' }}>Ultima Fecha</th>
                        <th scope='col' style={{ textAlign: 'center' }}>Hoja Bitacora</th>
                        <th scope='col' style={{ textAlign: 'center' }}>Proxima Fecha</th>
                      </Fragment>
                    ))}
                  </Fragment>
                ))}

                <th scope='col' style={{ backgroundColor: '#acb9d9' }} />
                {tareasCorrectivaTunel.map(t => (
                  <Fragment key={t.id}>
                    <th scope='col' style={{ textAlign: 'center' }}>Ultima Fecha</th>
                    <th scope='col' style={{ textAlign: 'center' }}>Hoja Bitacora</th>
                  </Fragment>
                ))}

              </tr>
            </thead>
            <tbody>
              {bombas.map((b, i) => (
                <tr key={`l${i}`}>
                  <th scope='row'>{i === 0 && 'Tunel'}</th>
                  <td>{b}</td>

                  {categoriasTunel.map(c => (
                    <Fragment key={c.nombre}>
                      <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                      {c.tareas.map(t => {
                        const row = t.bitacoras.find(r => r.bomba === b)
                        return (
                          <Fragment key={t.id}>
                            {row
                              ? (
                                <>
                                  <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                                  <th scope='col'>{row.hoja}</th>
                                  <th scope='col' style={{ borderRightColor: 'black' }}>{row.fechaProyeccion}</th>
                                </>
                                )
                              : (
                                <>
                                  <th scope='col' />
                                  <th scope='col' />
                                  <th scope='col' style={{ borderRightColor: 'black' }} />
                                </>
                                )}

                          </Fragment>
                        )
                      })}
                    </Fragment>
                  ))}

                  <td style={{ backgroundColor: '#acb9d9', borderRightColor: 'black' }} />
                  {tareasCorrectivaTunel.map(t => {
                    const row = t.bitacoras.find(r => r.bomba === b)
                    return (
                      <Fragment key={t.id}>
                        {row
                          ? (
                            <>
                              <th scope='col' style={{ borderLeftColor: 'black' }}>{row.fecha}</th>
                              <th scope='col' style={{ borderRightColor: 'black' }}>{row.hoja}</th>
                            </>
                            )
                          : (
                            <>
                              <th scope='col' />
                              <th scope='col' style={{ borderRightColor: 'black' }} />
                            </>
                            )}

                      </Fragment>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
