import { Fragment, useState } from 'react'

import { Loading } from '../componentes/States'
import { gql, useQuery, useMutation } from '@apollo/client'
import classnames from 'classnames'

import styles from '../../css/ConfigBox.module.scss'

import { Row, Col, Button } from 'reactstrap'

export default function Tiempos () {
  const { data, loading } = useQuery(gql`
  query{
    extractoraTags {
      lav
      programas {
        programa
        tiempos {
          alcalino01
          blanqueador
          detergente
          sanitizante
        }
      }
    }
  }
`)

  console.log(data)

  return (
    <>
      <h2 className='text-center'>Configuración tiempos de extractoras</h2>
      <h5 className='text-center'>(en segundos)</h5>
      <Row className='mt-4'>
        {loading && <Loading />}
        {data && data.extractoraTags.map((items, i) => <LavBox key={i} items={items} />)}
      </Row>
    </>
  )
}

function LavBox (props) {
  const [open, setOpen] = useState()
  const { lav, programas } = props.items
  const extractoraTags = open && Object.entries(programas.find(p => p.programa === open).tiempos).filter(([key]) => !key.startsWith('_'))

  return (
    <Col key={lav} md={3} className={styles.configBox}>
      <Row className={styles.title}>
        <h3>{lav}{open && ` - ${open}`}</h3>
      </Row>

      <Row className={classnames(styles.content, 'px-5')}>
        {open
          ? (
            <Col>
              <Row>
                <Col>
                  <Button color='primary' className='w-100 my-2' onClick={() => setOpen()}>
                    Volver
                  </Button>
                </Col>
              </Row>

              <Row className={classnames(styles.content, 'px-3 d-none d-md-flex')}>
                <Col xs='auto'>
                  {extractoraTags.map(([quimico]) => <Row key={quimico}><label>{quimico}: </label></Row>)}
                </Col>
                <Col>
                  {extractoraTags.map(values => <Row key={values[0]}><ExtractoraInput values={values} programa={open} lav={lav} /></Row>)}
                </Col>
              </Row>

              <Row className={classnames(styles.content, 'd-md-none')}>
                <Col>
                  {extractoraTags.map(values => (
                    <Fragment key={values[0]}>
                      <label style={{ marginTop: '15px' }}>{values[0]}: </label>
                      <ExtractoraInput key={values[0]} values={values} programa={open} lav={lav} />
                    </Fragment>
                  ))}
                </Col>
              </Row>
            </Col>
            )
          : programas.map(({ programa }) => (
            <Col key={programa} md={12}>
              <Button color='primary' className='w-100 my-2' onClick={() => setOpen(programa)}>
                {programa.replace(/([A-Z])/g, ' $1').trim()}
              </Button>
            </Col>
          ))}
      </Row>

    </Col>
  )
}

function ExtractoraInput ({ values, programa, lav }) {
  const [quimico, value] = values
  const [defaultValue, setDefault] = useState(value)

  let timeout = null
  const [sendTag, { loading }] = useMutation(gql`
    mutation SendExtractoraTiempo ($data: ExtractoraTiempoInput!){
      sendExtractoraTiempo(data: $data)
    }
  `)

  function handleChange (ev) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      sendTag({ variables: { data: { lav, programa, quimico, value: parseFloat(ev.target.value) } } })
        .then(res => setDefault(res.data.setTarado))
    }, 1000)
  }

  return <input disabled={loading} type='text' size='4' defaultValue={defaultValue} onChange={handleChange} style={{ width: '35px' }} />
}
