import { Row, Col } from 'reactstrap'
import { Fragment, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import { Pendientes } from '.'

export default function Planificador () {
  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())

  return (
    <>
      <h2 className='text-center mb-4'>Planificador</h2>
      <Row className='mx-3'>
        <Col>
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Inicio'
            value={start}
            onChange={handleStart}
          />
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Fin'
            value={end}
            onChange={handleEnd}
          />
        </Col>
      </Row>

      <Row className='mt-4 mx-3'>
        <Col>
          <Pendientes dates={[start, end]} />
        </Col>
      </Row>
    </>
  )
}
