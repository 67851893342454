import RingLoader from 'react-spinners/RingLoader'

export default function State (props) {
  let element
  switch (props.state) {
    case 'loading':
      element = <Loading />
      break

    case 'error':
      element = <Error err={props.err} />
      break

    default:
      element = <div />
      break
  }

  return element
}

export function Error ({ err }) {
  if (err) console.log(err)
  return (
    <div className='mt-4' style={{ width: '100%', textAlign: 'center' }}>
      <img alt='failed_report' style={{ width: '60px' }} src='/img/x-button.png' />
      <br />Fallo de Reporte
    </div>
  )
}

export function Loading () {
  return (
    <div className='mt-4' style={{ width: '100%', textAlign: 'center' }}>
      <RingLoader color='#123abc' css={{ margin: 'auto' }} />
      Cargando
    </div>
  )
}
