import { useRef, useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import { Col, Row } from 'reactstrap'
import { Loading } from '../componentes/States'

const cols = ['lavadora', 'suciedad', 'programa', 'responsable', 'supervisor', 'grado', 'prenda', 'hospital', 'reproceso', 'total', 'lavados', 'observaciones']

const query = gql`
    query Registro($start: String!, $end:String!, $turno: Int!) {
        extractoraRegistro(start: $start, end: $end, turno: $turno) {
          lavadora
          observaciones
          responsable
          grado
          hospital
          lavados
          prenda
          reproceso
          suciedad
          total
          supervisor
          turno
          programa
        }
    }
`

export default function Registro () {
  const turnoRef = useRef()

  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())
  const [loadData, { data, loading }] = useLazyQuery(query)

  function submit () {
    const turno = parseInt(turnoRef.current.value)
    if (start && end && turno) {
      loadData({
        variables: { start, end, turno: parseInt(turnoRef.current.value) }
      })
    }
  }

  useEffect(submit, [start, end, loadData])

  return (
    <div className='container'>
      <h2 className='text-center'>Reporte extractoras</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <form id='addUser' className='form-horizontal'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group form-row'>
                    <div className='col'>
                      <DatePicker
                        renderInput={(props) => <TextField className='w-50' {...props} />}
                        label='Inicio'
                        value={start}
                        onChange={handleStart}
                      />
                      <DatePicker
                        renderInput={(props) => <TextField className='w-50' {...props} />}
                        label='Fin'
                        value={end}
                        onChange={handleEnd}
                      />
                    </div>
                    <Col md='auto'>
                      <label htmlFor='turno'>Turno:</label>
                      <input
                        type='number' min={1} max={3} name='turno' className='form-control inputChange' id='turno'
                        required='required' ref={turnoRef} onChange={submit}
                      />
                    </Col>
                  </div>

                  {loading && (
                    <Row>
                      <Col>
                        <Loading />
                      </Col>
                    </Row>
                  )}

                  <div className='form-group table-responsive'>
                    <table className='table' id='table'>
                      <thead>
                        <tr>
                          <th className='text-left' scope='col'>Lavadora</th>
                          <th className='text-left' scope='col'>Tipo Suciedad</th>
                          <th className='text-left' scope='col'>Programa</th>
                          <th className='text-left' scope='col'>Responsable</th>
                          <th className='text-left' scope='col'>Supervisor</th>
                          <th className='text-left' scope='col'>Grado de Suciedad</th>
                          <th className='text-left' scope='col'>Tipo de Prenda</th>
                          <th className='text-left' scope='col'>Hospital</th>
                          <th className='text-left' scope='col'>Reproceso</th>
                          <th className='text-left' scope='col'>Codigo Lavado</th>
                          <th className='text-left' scope='col'>Kg lavados</th>
                          <th className='text-left' scope='col'>Observaciones</th>
                        </tr>
                      </thead>
                      <tbody id='rows'>
                        {data && data.extractoraRegistro.map((row, i) => (
                          <tr key={i}>
                            {cols.map(col => <td key={col} className={col === 'hospital' ? 'bold' : undefined}>{row[col] || ''}</td>)}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
