import Ingreso from './ingreso'
import Registro from './registro'
import Modificar from './modificar'
import Quimicos from './quimicos'
import Suciedad from './registroSuciedad'
import Lavadora from './registroLavadora'
import SuciedadCapacidad from './registroSuciedadCapacidad'
import LavadoraCapacidad from './registroLavadoraCapacidad'
import Tiempos from './tiempos'
import Tarados from './tarados'

export default {
  '/extractora/quimicos': () => <Quimicos />,
  '/extractora/suciedad': () => <Suciedad />,
  '/extractora/lavadora': () => <Lavadora />,
  '/extractora/capacidad/suciedad': () => <SuciedadCapacidad />,
  '/extractora/capacidad/lavadora': () => <LavadoraCapacidad />,
  '/extractora/ingreso': () => <Ingreso />,
  '/extractora/registro': () => <Registro />,
  '/extractora/modificar': () => <Modificar />,
  '/extractora/tarado': () => <Tarados />,
  '/extractora/tiempos': () => <Tiempos />
}
