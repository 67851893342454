import { Component, createRef, Fragment } from 'react'
import { getAPI, postAPI } from './FormUtil'
import moment from 'moment'

import { Col, Row, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap'
import { toast } from 'react-toastify'

// Tabla
export default class TablaDeInformacion extends Component {
  state = { ordenes: [] }

  componentDidMount = () => {
    getAPI('inventario/orden/actual').then(res => {
      this.setState({ ordenes: res.data })
    })
  }

  render () {
    return (
      <div>
        <Row className='mt-2 justify-content-center'><h2>{this.props.consulta && 'Consulta '}Órdenes Actuales</h2></Row>
        {this.state.ordenes.length === 0
          ? <div style={{ alignItems: 'center', display: 'flex' }}><h2 className='d-inline'>No hay órdenes en progreso</h2></div>
          : this.state.ordenes.map((row, i) => <Fragment key={row.id}>{i === 0 ? '' : <hr />}<Orden consulta={this.props.consulta} refreshOrdenes={this.componentDidMount} row={row} /></Fragment>)}
      </div>

    )
  }
};

class Orden extends Component {
  state={ items: [], modal: false, log: [], devolucion: {}, confirmed: false, timer: 0, confirmedDevolucion: false, timerDevolucion: 0 }
  observacion = createRef()

  componentDidMount () {
    getAPI(`inventario/orden/${this.props.row.id}`).then(res => {
      this.setState({ items: res.data })
    })
    getAPI(`inventario/orden/log/${this.props.row.id}`).then(res => {
      this.setState({ log: res.data })
    })
  }

  handleFinalizar = () => {
    if (this.state.confirmed) {
      const data = { devolucion: false, id: this.props.row.id, changes: this.state.items, destino: this.props.row.destino }
      if (this.props.row.estado === 'devolucion') {
        data.devolucion = true
        data.destino = this.props.row.origen
      }
      postAPI('inventario/orden/finalizar', data).then(() => {
        toast.success('Orden finalizada correctamente!')
        this.props.refreshOrdenes()
      })
    } else {
      toast.info('Revise la órden durante 30 segundos')
      this.setState({ timer: 30 }, () => {
        const interval = setInterval(() => {
          if (this.state.timer > 0) {
            this.setState({ timer: this.state.timer - 1 })
          } else {
            clearInterval(interval)
            this.setState({ confirmed: true })
          }
        }, 1000)
      })
    }
  }

  onHandleToggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  handleToggle = this.onHandleToggle

  handleDevolucion = () => {
    if (this.state.confirmedDevolucion) {
      postAPI('inventario/orden/devolucion', { observacion: this.observacion.current.value, devolucion: this.state.devolucion, id: this.props.row.id, changes: this.state.items, destino: this.props.row.destino })
        .then(() => {
          toast.success('Devolucion registrada!')
          this.props.refreshOrdenes()
        })
      this.handleToggle()
    } else {
      toast.info('Revise la devolucion durante 30 segundos')
      this.setState({ timerDevolucion: 30 }, () => {
        const interval = setInterval(() => {
          if (this.state.timerDevolucion > 0) {
            this.setState({ timerDevolucion: this.state.timerDevolucion - 1 })
          } else {
            clearInterval(interval)
            this.setState({ confirmedDevolucion: true })
          }
        }, 1000)
      })
    }
  }

  closeBtn = <button className='close' onClick={this.handleToggle}>&times;</button>

  render () {
    return (
      <Row>
        <Modal isOpen={this.state.modal} toggle={this.onHandleToggle}>
          <ModalHeader toggle={this.onHandleToggle} close={this.closeBtn}>Devolucion</ModalHeader>
          <ModalBody>
            <legend>Órden #{this.props.row.id}</legend>
            <div className='table-responsive'>
              <table className='table table-striped' style={{ width: 'auto' }}>
                <thead>
                  <tr>
                    <th scope='col'>Código</th>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(row => (
                    <tr key={row.item}>
                      <td>{row.item}</td>
                      <td>{row.nombre}</td>
                      <td>
                        <Input
                          type='number' onChange={e => {
                            e.persist()
                            const data = this.state.devolucion
                            if (e.target.value === '0') delete data[row.item]
                            else data[row.item] = e.target.value
                            this.setState({ devolucion: data })
                          }} max={row.cantidad} defaultValue={0}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for='nombre'>Observacion</Label>
                  <Input type='text' innerRef={this.observacion} name='observacion' defaultValue='N/A' required maxLength={200} />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleDevolucion} disabled={!this.state.confirmedDevolucion && this.state.timerDevolucion > 0}>{!this.state.confirmedDevolucion && this.state.timerDevolucion > 0 ? `Confirmar (${this.state.timerDevolucion})` : 'Confirmar'}</Button>
          </ModalFooter>
        </Modal>

        <Col md={12}>
          <legend>Órden #{this.props.row.id}</legend>
          <caption style={{ width: '100%' }}>Transportista: {this.props.row.transportista} / Marchamo: {this.props.row.marchamo}</caption>
          <Row>
            <Col md={6} className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th scope='col'>Código</th>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(row => (
                    <tr key={row.item}>
                      <td>{row.item}</td>
                      <td>{row.nombre}</td>
                      <td>{row.cantidad}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col md={6} className='table-responsive col-md-6'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th scope='col'>Estado</th>
                    <th scope='col'>Dia</th>
                    <th scope='col'>Hora</th>
                    <th scope='col'>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.log.sort((a, b) => moment(a.tiempo).valueOf() - moment(b.tiempo).valueOf()).map(row => {
                    const date = moment(row.tiempo)
                    return (
                      <tr key={row.estado}>
                        <td>{row.estado}</td>
                        <td>{date.format('D - MMM - YYYY')}</td>
                        <td>{date.format('h:mm A')}</td>
                        <td>{row.usuario}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
          {this.props.consulta && (
            <>
              <Row className='mt-3' style={{ paddingLeft: '15px' }}>
                <FormGroup className='mr-2 mb-0'>
                  <Button onClick={this.handleFinalizar} color='primary' disabled={!this.state.confirmed && this.state.timer > 0}>{!this.state.confirmed && this.state.timer > 0 ? `Finalizar Órden (${this.state.timer})` : 'Finalizar órden'}</Button>
                </FormGroup>

                <FormGroup className='mr-2 mb-0'>
                  <Button color='primary' disabled={this.props.row.estado === 'devolucion'} onClick={this.handleToggle}>Devolucion</Button>
                </FormGroup>
              </Row>
            </>
          )}
        </Col>
      </Row>
    )
  }
}
