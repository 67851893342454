import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'

const inputStyle = { position: 'absolute', top: 0, left: 0, width: '5.4%' }

export default function Diagrama () {
  const { data, error } = useQuery(gql`
        query Info {
            tunelInfo
            tunelConfig
        }
    `, { pollInterval: 500 })

  useEffect(() => {
    if (error) console.log(error)
  }, ['error'])

  return (
    <Container id='infoBorder' className='border border-primary p-4'>
      <Row>
        <Col md='12' style={{ position: 'relative' }}>
          <img style={{ height: 'auto', width: '100%' }} src='/img/diagrama.png' />

          <div style={{ ...inputStyle, top: '30%', left: '28.2%' }}>
            <input value={!data ? '***' : `${data.tunelInfo['Temp Etapa 3'].toFixed(2)} ºC`} readOnly style={{ width: '100%' }} />
          </div>
          <div style={{ ...inputStyle, top: '40%', left: '28.2%' }}>
            <input value={!data ? '***' : `${data.tunelInfo['PH etapa 3'].toFixed(2)} %`} readOnly style={{ width: '100%' }} />
          </div>

          <div style={{ ...inputStyle, top: '30%', left: '84.4%' }}>
            <input value={!data ? '***' : `${data.tunelInfo['Temp Etapa 12'].toFixed(2)} ºC`} readOnly style={{ width: '100%' }} />
          </div>
          <div style={{ ...inputStyle, top: '40%', left: '84.4%' }}>
            <input value={!data ? '***' : `${data.tunelInfo['PH etapa 12'].toFixed(2)} %`} readOnly style={{ width: '100%' }} />
          </div>
        </Col>
      </Row>
      <Row className='justify-content-between mt-3'>
        <Col md='auto'>
          <label htmlFor='concentracion' className='mr-2'>Concentracion de peróxido de hidrógeno (Etapa 3): </label>
          <input name='concentracion' value={`${!data ? '***' : data.tunelConfig['Concentracion Tunel Etapa 3']} ppm`} readOnly />
        </Col>
        <Col md='auto'>
          <label htmlFor='alcalinidad' className='mr-2'>Alcalinidad total (Etapa 3): </label>
          <input name='alcalinidad' value={`${!data ? '***' : data.tunelConfig['Alcalinidad Tunel Etapa 3']} ppm`} readOnly />
        </Col>
      </Row>
      <Row className='justify-content-between mt-4'>
        <Col md='auto'>
          <label htmlFor='concentracion' className='mr-2'>Concentracion de peróxido de hidrógeno (Etapa 8): </label>
          <input name='concentracion' value={`${!data ? '***' : data.tunelConfig['Concentracion Tunel Etapa 8']} ppm`} readOnly />
        </Col>
        <Col md='auto'>
          <label htmlFor='alcalinidad' className='mr-2'>Alcalinidad total (Etapa 8): </label>
          <input name='alcalinidad' value={`${!data ? '***' : data.tunelConfig['Alcalinidad Tunel Etapa 8']} ppm`} readOnly />
        </Col>
      </Row>
      <Row className='justify-content-between mt-4'>
        <Col md='auto'>
          <label htmlFor='concentracion' className='mr-2'>Concentracion de peróxido de hidrógeno (Prensa): </label>
          <input name='concentracion' value={`${!data ? '***' : data.tunelConfig['Concentracion Tunel Prensa']} ppm`} readOnly />
        </Col>
        <Col md='auto'>
          <label htmlFor='alcalinidad' className='mr-2'>Alcalinidad total (Prensa): </label>
          <input name='alcalinidad' value={`${!data ? '***' : data.tunelConfig['Alcalinidad Tunel Prensa']} ppm`} readOnly />
        </Col>
      </Row>
    </Container>
  )
}
