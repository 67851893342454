import { Row, Col } from 'reactstrap'
import { gql, useQuery } from '@apollo/client'
import { Fragment, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import States from '../componentes/States'
import classnames from 'classnames'

export default function Reporte () {
  return (
    <>
      <Pendientes />
    </>
  )
}

function Pendientes () {
  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())

  const query = gql`
  query Bitacora($dates: [String!]!) {
    bitacoras(dates: $dates){
      id
      fecha
      tarea {
        categoria
        nombre
      }
      lavadora
      bomba
      hoja
      observaciones
    }

    bitacorasTunel(dates: $dates){
      id
      fecha
      tarea {
        categoria
        nombre
      }
      bomba
      hoja
      observaciones
    }

    bitacorasCorrectiva(dates: $dates){
      id
      fecha
      tarea {
        nombre
        categoria
      }
      lavadora
      bomba
      hoja
      observaciones
    }

    bitacorasCorrectivaTunel(dates: $dates){
      id
      fecha
      tarea {
        nombre
        categoria
      }
      bomba
      hoja
      observaciones
    }
  }
`
  const { data, loading, error, refetch } = useQuery(query, { variables: { dates: [start, end] } })
  useEffect(() => refetch({ variables: { dates: [start, end] } }), [start, end, refetch])

  const rows = data
    ? [
        ...data.bitacorasTunel, ...data.bitacoras,
        ...data.bitacorasCorrectivaTunel, ...data.bitacorasCorrectiva
      ]
        .sort((a, b) => (a.fecha > b.fecha) ? 1 : -1)
    : []

  const boxes = {}
  if (data) {
    rows.forEach(b => {
      if (!boxes[b.tarea.categoria]) boxes[b.tarea.categoria] = { [b.tarea.nombre]: [b] }
      else if (!boxes[b.tarea.categoria][b.tarea.nombre]) boxes[b.tarea.categoria][b.tarea.nombre] = [b]
      else boxes[b.tarea.categoria][b.tarea.nombre].push(b)
    })
  }

  return (
    <>
      <h2 className='text-center mb-4'>Reporte de Tareas</h2>
      <Row>
        <Col>
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Inicio'
            value={start}
            onChange={handleStart}
          />
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Fin'
            value={end}
            onChange={handleEnd}
          />
        </Col>
      </Row>

      {error && <States state='error' err={error} />}
      {loading && <States state='loading' />}

      <Row className='mt-2'>
        {Object.entries(boxes).map(([cat, tareas]) =>
          Object.entries(tareas).map(([tarea, bitacoras]) =>
            <Col className='my-2' md={6} key={`${cat}_${tarea}`}>
              <div className='list-group list-group-root'>
                <a className='list-group-item list-group-item-action'>
                  {cat} - {tarea}
                </a>

                {bitacoras.sort((a, b) => (a.fecha > b.fecha) ? 1 : -1).map((b, i) => (
                  <div key={b.id} className={classnames('list-groupe', { 'mt-3': i > 0 })}>
                    <a className='list-group-item list-group-item-action'>
                      {b.fecha} - {b.lavadora} - {b.bomba} - Hoja #{b.hoja}
                    </a>
                    {b.observaciones && <div className='list-group'><a className='list-group-item list-group-item-action'>{b.observaciones}</a></div>}
                  </div>
                ))}
              </div>
            </Col>
          )
        )}
      </Row>
    </>
  )
}
