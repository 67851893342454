import Cookies from 'js-cookie'
import { useContext } from 'react'
import { Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { navigate } from 'hookrouter'

import 'react-toastify/dist/ReactToastify.min.css'

import { gql, useMutation } from '@apollo/client'
import serialize from 'form-serialize'

import UserContext from '../UserContext'
import loader from 'svg-loaders/svg-smil-loaders/oval.svg'

export default function Login () {
  const { refetch } = useContext(UserContext)
  const query = gql`
  mutation Login(
    $username: String!,
    $password: String!
  ){
    login(
      username: $username,
      password: $password
    )
    {
        token
        permitido
    }
  }
`
  const [login, { loading }] = useMutation(query)

  function resolve () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('redirect')) navigate(urlParams.get('redirect'))
    else navigate('/')
  }

  function submit (e) {
    e.persist()
    e.preventDefault()
    const variables = serialize(e.target, { hash: true })

    login({ variables }).then(result => {
      toast.success('Logged in succesfully!')
      Cookies.set('token', result.data.login.token, { sameSite: 'Lax' })

      refetch()
      resolve()
    }).catch(error => {
      console.log(error)
      Cookies.remove('token')

      toast.error(error.graphQLErrors[0].message, { autoclose: false })
    })
  }

  return (
    <Col md={12} className='d-flex align-items-center justify-content-center' style={{ marginTop: '200px' }}>
      <form onSubmit={submit} className=''>
        <div className='form-row' style={{ fontSize: '16px' }}>
          <div className='form-group mx-auto'>
            <label htmlFor='user'>Nombre de usuario</label>
            <input type='text' className='form-control ' id='user' name='username' required='required' />
          </div>
          <div className='form-group mx-auto'>
            <label className='ml-2' htmlFor='password'>Contraseña</label>
            <input type='password' className='form-control ml-2' id='pass' name='password' required='required' />
          </div>
        </div>
        <button className='btn btn-primary' type='submit'>{loading ? <img src={loader} alt='loading' /> : 'Ingresar'}</button>
      </form>
      <div />
    </Col>
  )
}
