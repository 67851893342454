import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import DateTimePicker from '@material-ui/lab/DateTimePicker'
import { Loading } from '../componentes/States'
import { gql, useLazyQuery } from '@apollo/client'

import { DateTime } from 'luxon'
import moment from 'moment'

const columns = ['programa', 'peso', 'cliente', 'camara_3', 'camara_8', 'camara_9', 'camara_11', 'tanque_1', 'tanque_2']
const query = gql`
  query Registro($start: Float!, $end: Float!) {
    tunelRegistro(start: $start, end: $end) {
      startTime
      programa
      peso
      cliente
      camara_3
      camara_8
      camara_9
      camara_11
      tanque_1
      tanque_2
      }
  }
`

export default function Registro () {
  const [loadData, { data, loading }] = useLazyQuery(query)
  const [start, handleStart] = useState(DateTime.local())
  const [end, handleEnd] = useState(DateTime.local())

  useEffect(() => loadData({ variables: { start: start.toMillis(), end: end.toMillis() } }), [start, end, loadData])

  return (
    <div className='container'>
      <h2 className='text-center'>Reporte túnel</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <div className='form-group form-row'>
              <div className='col'>
                <DateTimePicker
                  renderInput={(props) => <TextField className='w-50' {...props} />}
                  label='Inicio'
                  value={start}
                  onChange={handleStart}
                  inputFormat='dd/MM/yyyy t'
                />
                <DateTimePicker
                  renderInput={(props) => <TextField className='w-50' {...props} />}
                  label='Fin'
                  value={end}
                  onChange={handleEnd}
                  inputFormat='dd/MM/yyyy t'
                />
              </div>
            </div>
            <div id='root' className='table-responsive'>
              {loading && <Loading />}

              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th scope='col'>Fecha</th>
                    <th scope='col'>Hora</th>
                    {columns.map(column => {
                      return <th scope='col' key={column}>{column}</th>
                    })}
                  </tr>
                </thead>
                {data && (
                  <tbody>
                    {data.tunelRegistro && data.tunelRegistro.map(row => (
                      <tr key={row.startTime}>
                        <td>{moment(parseInt(row.startTime)).format('DD-MM-YYYY')}</td>
                        <td>{moment(parseInt(row.startTime)).format('HH:mm')}</td>
                        {columns.map(c => <td key={c}>{row[c]}</td>)}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
