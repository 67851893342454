import { useState, useEffect } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { Col, Row, Button } from 'reactstrap'
import { downloadCSV, downloadPDF } from '../utils'

import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'

const query = gql`
  query Factura($start: String!, $end: String!) {
    res: reporteFactura(start:$start, end:$end)
  }
`
const querySuciedad = gql`
  query Suciedad {
    suciedades
    tunelSuciedades
  }
`

export default function Content () {
  const [dates, setDates] = useState()
  const [loadData, { err, data, loading }] = useLazyQuery(query)
  const { err: errSuciedad, data: dataSuciedad, loading: loadingSuciedad } = useQuery(querySuciedad)

  function handleData () {
    if (dates) loadData({ variables: { start: dates[0], end: dates[1] } })
  }

  useEffect(handleData, [dates])

  function printPDF () {
    downloadPDF(`Reporte Facturación ${dates.join(' - ')}`, document.getElementById('ReporteKilogramos'))
  }

  function printCSV () {
    downloadCSV(`Reporte Facturación ${dates.join(' - ')}`, document.getElementById('ReporteKilogramos'))
  }

  return (
    <>
      <Row className='mt-2 justify-content-center'><h2>Reporte Facturación</h2></Row>
      <Row className='mt-2'>
        <Col md='auto' className='d-flex justify-content-start'>
          <DatePicker simple handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} id='datepick' />
        </Col>
        <Col className='my-4 my-md-0 mt-md-auto d-flex justify-content-center justify-content-md-end'>
          <Button onClick={printPDF} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar PDF</Button>
          <Button onClick={printCSV} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar CSV</Button>
        </Col>
      </Row>
      <Row className='mt-5 justify-content-center'>
        {dataSuciedad && Object.entries(dataSuciedad.tunelSuciedades).map(([suciedad, codigos]) => (
          <Col md='auto' key={suciedad}>
            <span style={{ color: '#4158d4' }} className='mr-1'>Túnel {suciedad}</span><span>= {codigos.join(' - ')}</span>
          </Col>
        ))}
      </Row>
      <Row className='mt-4'>
        <Col md={12}>
          <State state={(err || errSuciedad) ? 'error' : ((loadingSuciedad || loading) ? 'loading' : '')} />
          {dataSuciedad && <Reporte suciedad={dataSuciedad} data={data} dates={dates} />}
        </Col>
      </Row>
    </>
  )
}

function Reporte (props) {
  return (
    <Row>
      <Col>
        <div className='table-responsive'>
          <table id='ReporteKilogramos' className='table table-striped'>
            <thead>
              <tr>
                <th colSpan={2 + props.suciedad.suciedades.length} scope='row'>Reporte de facturación {props.dates && props.dates.join(' - ')}</th>
              </tr>
              <tr>
                <th scope='col'>Lavadora</th>
                {
                  props.suciedad.suciedades.map(prog => <th scope='col' key={prog}>{prog}</th>)
                }
                <th scope='col'>Total</th>
              </tr>
            </thead>
            {props.data && (
              <tbody>
                {Object.entries(props.data.res.lavs).map(([lav, { suciedad, total }]) => (
                  <tr key={lav}>
                    <td>{lav}</td>
                    {props.suciedad.suciedades.map(prog => <td key={prog}>{suciedad[prog] || 0}</td>)}
                    <td>{total}</td>
                  </tr>
                ))}

                <tr style={{ backgroundColor: 'white', fontWeight: 'bold' }}>
                  <td>TOTAL</td>
                  {props.suciedad.suciedades.map(prog => <td key={prog}>{props.data.res.suciedad[prog] || 0}</td>)}
                  <td>{props.data.res.total}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </Col>
    </Row>
  )
}
