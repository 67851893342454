/* global FormData */
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import { gql, useMutation } from '@apollo/client'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'

import classnames from 'classnames'

const mutation = gql`
  mutation create($nombre: String!, $intervaloSemanas: String, $categoria: String!) {
    createTareaGeneral(nombre: $nombre, intervaloSemanas: $intervaloSemanas, categoria: $categoria){ nombre }
  }
`

export default function Ingreso () {
  const [tipo, setCat] = useState('Mantenimiento Preventivo')
  const [send, { loading }] = useMutation(mutation)

  function handleSubmit (event) {
    event.preventDefault()
    const variables = Object.fromEntries(new FormData(event.target))

    send({ variables })
      .then(() => toast.success('Tarea agregada!'))
      .catch(err => {
        console.log(err)
        toast.error('Error al guardar tarea')
      })
  }

  return (
    <>
      <h2 className='text-center mb-4'>Configuracion de Tareas Generales</h2>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col className=''>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='categoria'>Categoria</Label>
                      <Input type='select' name='categoria' onChange={ev => setCat(ev.target.value)}>
                        <option value='Mantenimiento Preventivo'>Mantenimiento Preventivo</option>
                        <option value='Mantenimiento Correctivo'>Mantenimiento Correctivo</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='nombre'>Nombre de tarea</Label>
                      <Input type='text' name='nombre' required />
                    </FormGroup>
                  </Col>
                </Row>

                {tipo === 'Mantenimiento Preventivo' && (
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='intervaloSemanas'>Intervalo de semanas</Label>
                        <Input type='number' name='intervaloSemanas' required />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <button type='submit' className={classnames('btn btn-primary', { disabled: loading })}>{loading ? 'Enviando..' : 'Enviar'}</button>
          </Form>
        </Col>
      </Row>
    </>
  )
}
