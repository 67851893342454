import { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import DateTimePicker from '@material-ui/lab/DateTimePicker'
import { gql, useLazyQuery } from '@apollo/client'
import { Container, Col, Row, Button } from 'reactstrap'
import { DateTime } from 'luxon'
import { downloadCSV, downloadPDF } from '../utils'

const quimicoColumns = ['Alcalino', 'Blanqueador', 'Detergente', 'Sanitizante', 'Total']
const baseColumns = ['Programa', ...quimicoColumns]

const query = gql`
    query Reporte($start: String!, $end: String!, $modo: String!, $densidad: Boolean) {
        tunelQuimicos(start: $start, end: $end, modo: $modo, densidad: $densidad) {
          value
          fecha
          total {
            Detergente
            Alcalino
            Blanqueador
            Sanitizante
            Total
          }
        }
    }
`

export default function Quimicos () {
  const [modo, setModo] = useState('registro')
  const [loadData, { data }] = useLazyQuery(query)

  const [mult, setMult] = useState(1)
  const [densidad, setDensidad] = useState(false)

  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())

  useEffect(() => loadData({ variables: { start, end, modo, densidad } }), [start, end, modo, densidad, loadData])

  const columns = modo === 'registro' ? baseColumns : ['Fecha', ...baseColumns]

  const totalQuimicos = {}

  if (data) {
    data.tunelQuimicos.forEach(o => {
      Object.entries(o.total).forEach(([quimico, valor]) => {
        if (!totalQuimicos[quimico]) totalQuimicos[quimico] = valor
        else totalQuimicos[quimico] += valor
      })
    })
  }

  function printPDF () {
    downloadPDF(`Reporte túnel químicos ${[start.toLocaleString(DateTime.DATETIME_SHORT), end.toLocaleString(DateTime.DATETIME_SHORT)].join(' - ')}`, document.getElementById('rootPDF'))
  }

  function printCSV () {
    downloadCSV(`Reporte túnel químicos ${[start.toLocaleString(DateTime.DATETIME_SHORT), end.toLocaleString(DateTime.DATETIME_SHORT)].join(' - ')}`, document.getElementById('rootPDF'))
  }

  return (
    <Container>
      <Row className='justify-content-center'><h2>Reporte túnel químicos (por programa)</h2></Row>
      <Row className='mt-5 d-flex justify-content-center'>
        <Col xs='auto' className='my-4 my-md-0 mt-md-auto  justify-content-md-end'>
          <Button onClick={printPDF} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar PDF</Button>
          <Button onClick={printCSV} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar CSV</Button>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <DateTimePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Inicio'
            value={start}
            onChange={handleStart}
          />
          <DateTimePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Fin'
            value={end}
            onChange={handleEnd}
          />
        </Col>

        <Col md='auto' className='py-2 py-md-0'>
          <h5>Seleccionar reporte:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio1' value='registro' defaultChecked onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio1'>Total</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio2' value='diario' onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio2'>Diario</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio3' value='mensual' onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio3'>Mensual</label>
          </div>
        </Col>

        <Col md='auto' className='py-2 py-md-0'>
          <h5>Seleccionar medida:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(1); setDensidad(false) }} defaultChecked />
            <label className='form-check-label'>Mililitros (mL)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(0.001); setDensidad(false) }} />
            <label className='form-check-label'>Litros (L)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(1); setDensidad(true) }} />
            <label className='form-check-label'>Kilogramos (Kg)</label>
          </div>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <div id='rootPDF' className='table-responsive'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  {columns.map(column => {
                    return <th scope='col' key={column}>{column}</th>
                  })}
                </tr>
              </thead>

              <tbody>
                {data && (
                  <>
                    {data.tunelQuimicos.map(({ fecha, value, total }, i) => (
                      <tr key={i}>
                        {fecha && <td>{fecha}</td>}
                        <td>{value}</td>
                        {quimicoColumns.map(q => <td key={q}>{(total[q] * mult).toFixed(2)}</td>)}
                      </tr>
                    ))}
                    <tr style={{ fontWeight: 'bold', backgroundColor: 'rgb(181, 230, 249)' }}>
                      {modo !== 'registro' && <td />}
                      <td>TOTAL: </td>
                      {quimicoColumns.map(q => <td key={q}>{(totalQuimicos[q] * mult).toFixed(2)}</td>)}
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
