import Configuracion from './configuracion'
import Admin from './admin'
import Orden from './crearOrden'
import OrdenesActuales from './ordenesActuales'
import OrdenesFinalizados from './ordenesFinalizadas'

export default {
  '/inventario/config': () => <Configuracion />,
  '/inventario/admin': () => <Admin consulta={false} />,
  '/inventario/admin/consulta': () => <Admin consulta />,
  '/inventario/orden': () => <Orden />,
  '/inventario/ordenes': () => <OrdenesActuales />,
  '/inventario/ordenes/consulta': () => <OrdenesActuales consulta />,
  '/inventario/ordenes/final': () => <OrdenesFinalizados />
}
