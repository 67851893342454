/* global FormData */
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Fragment, useState } from 'react'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { Pendientes } from './index'

const mutationLav = gql`
    mutation create($lavadora: String!, $bomba: String!, $hoja: String!, $tarea: ID!, $observaciones: String) {
        createBitacoraCorrectiva(lavadora: $lavadora, bomba: $bomba, hoja: $hoja, tarea: $tarea, observaciones: $observaciones)
    }
`

const mutationTunel = gql`
    mutation create($bomba: String!, $hoja: String!, $tarea: ID!, $observaciones: String) {
        createBitacoraCorrectivaTunel(bomba: $bomba, hoja: $hoja, tarea: $tarea, observaciones: $observaciones)
    }
`

export default function Ingreso () {
  const query = gql`
  query Bitacora {
    tareasCorrectiva {
      id
      nombre
    }

    tareasCorrectivaTunel {
      id
      nombre
    }

    staticInfo {
      lavadoras
      bombas
    }
  }
`
  const { data } = useQuery(query)
  const [sendLav, { loading: loadingLav }] = useMutation(mutationLav)
  const [sendTunel, { loading: loadingTunel }] = useMutation(mutationTunel)
  const [lav, setLav] = useState('Tunel')

  function handleSubmit (event) {
    event.preventDefault()
    const variables = Object.fromEntries(new FormData(event.target))

    const fn = lav === 'Tunel' ? sendTunel : sendLav
    fn({ variables })
      .then(() => toast.success('Bitacora agregada!'))
      .catch(err => {
        console.log(err)
        toast.error('Error al guardar bitacora')
      })
  }

  const tareas = data && (lav === 'Tunel' ? data.tareasCorrectivaTunel : data.tareasCorrectiva)
  const loading = lav === 'Tunel' ? loadingTunel : loadingLav

  return (
    <>
      <h2 className='text-center mb-4'>Ingreso Mantenimiento Correctivo</h2>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col className='pr-3'>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='lavadora'>Lavadora o Tunel</Label>
                      <Input type='select' name='lavadora' onChange={ev => setLav(ev.target.value)}>
                        {data && data.staticInfo.lavadoras.map(l => <option key={l} value={l}>{l}</option>)}
                        <option value='Tunel'>Tunel</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='tarea'>Tarea</Label>
                      <Input type='select' name='tarea'>
                        {data && tareas.map(t => <option key={t.id} value={t.id}>{t.nombre}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='bomba'>Bomba</Label>
                      <Input type='select' name='bomba'>
                        {data && data.staticInfo.bombas.map(l => <option key={l} value={l}>{l}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='hoja'>Hoja de bitacora</Label>
                      <Input type='text' name='hoja' required />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col className='pl-3'>
                <FormGroup>
                  <Label for='observaciones'>Observaciones</Label>
                  <Input type='textarea' name='observaciones' />
                </FormGroup>
              </Col>
            </Row>

            <button type='submit' className={classnames('btn btn-primary', { disabled: loading })}>{loading ? 'Enviando..' : 'Enviar'}</button>
          </Form>
        </Col>
      </Row>
      <Row className='mt-5 flex-grow-1'>
        <Col>
          <Row>
            <Col>
              Tareas Pendientes:
            </Col>
          </Row>
          <Pendientes pendientes />
        </Col>
      </Row>
    </>
  )
}
