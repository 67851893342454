import { Fragment, useState } from 'react'

import { Loading } from '../componentes/States'
import { gql, useQuery, useMutation } from '@apollo/client'
import classnames from 'classnames'

import styles from '../../css/ConfigBox.module.scss'

import { Row, Col } from 'reactstrap'

export default function Tarados () {
  const { data, loading } = useQuery(gql`
  query{
    extractoraTags {
      lav
      tarados {
        alcalino01
        blanqueador
        detergente
        sanitizante
      }
    }
  }
`)

  return (
    <>
      <h2 className='text-center'>Configuración tarados de extractoras</h2>
      <Row className='mt-4'>
        {loading && <Loading />}
        {data && data.extractoraTags.map(({ lav, tarados }) => {
          const quimicoTags = data && Object.entries(tarados).filter(([key]) => !key.startsWith('_'))
          return (
            <Col key={lav} md={4} className={styles.configBox}>
              <Row className={styles.title}>
                <h3>{lav}</h3>
              </Row>
              <Row className={classnames(styles.content, 'px-5 d-none d-md-flex')}>
                <Col md='auto'>
                  {quimicoTags.map(([quimico]) => <Row key={quimico}><label>{quimico}: </label></Row>)}
                </Col>
                <Col className='pr-1'>
                  {quimicoTags.map(values => <Row key={values[0]}><QuimicoInput values={values} lav={lav} /></Row>)}
                </Col>
                <Col md='auto' className='pr-0'>
                  {quimicoTags.map(values => (
                    <Row key={values[0]} style={{ marginTop: '5px' }}>
                      <Col className='pr-0'><span>ml/min</span></Col>
                    </Row>
                  ))}
                </Col>
              </Row>

              <Row className={classnames(styles.content, 'd-md-none')}>
                <Col>
                  {quimicoTags.map(values => (
                    <Fragment key={values[0]}>
                      <Row>
                        <Col>
                          <label style={{ marginTop: '15px' }}>{values[0]}: </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='pr-1'>
                          <QuimicoInput key={values[0]} values={values} lav={lav} />
                        </Col>
                        <Col xs='auto'>
                          <span>ml/min</span>
                        </Col>
                      </Row>
                    </Fragment>
                  ))}
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

function QuimicoInput ({ values, lav }) {
  const [quimico, value] = values
  const [defaultValue, setDefault] = useState(value)

  let timeout = null
  const [sendTag, { loading }] = useMutation(gql`
    mutation SendExtractoraTarado ($data: ExtractoraTaradoInput!){
      sendExtractoraTarado(data: $data)
    }
  `)

  function handleChange (ev) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      sendTag({ variables: { data: { lav, quimico, value: parseFloat(ev.target.value) } } })
        .then(res => setDefault(res.data.setTarado))
    }, 1000)
  }

  return (
    <>
      <input style={{ textAlign: 'right' }} disabled={loading} type='text' size='4' defaultValue={defaultValue} onChange={handleChange} />
    </>
  )
}
