import { Row, Col } from 'reactstrap'
import { gql, useQuery } from '@apollo/client'
import { Fragment, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import States from '../componentes/States'

export default function Bitacoras () {
  return (
    <>
      <Pendientes />
    </>
  )
}

function Pendientes () {
  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())

  const query = gql`
  query Bitacora($dates: [String!]!) {
    bitacoras(dates: $dates){
      id
      fecha
      tarea {
        categoria
        nombre
      }
      lavadora
      bomba
      hoja
      observaciones
    }

    bitacorasTunel(dates: $dates){
      id
      fecha
      tarea {
        categoria
        nombre
      }
      bomba
      hoja
      observaciones
    }

    bitacorasCorrectiva(dates: $dates){
      id
      fecha
      tarea {
        nombre
        categoria
      }
      lavadora
      bomba
      hoja
      observaciones
    }

    bitacorasCorrectivaTunel(dates: $dates){
      id
      fecha
      tarea {
        nombre
        categoria
      }
      bomba
      hoja
      observaciones
    }
  }
`
  const { data, loading, error, refetch } = useQuery(query, { variables: { dates: [start, end] } })
  useEffect(() => refetch({ variables: { dates: [start, end] } }), [start, end, refetch])

  const rows = data
    ? [
        ...data.bitacorasTunel, ...data.bitacoras,
        ...data.bitacorasCorrectivaTunel, ...data.bitacorasCorrectiva
      ]
        .sort((a, b) => (a.fecha > b.fecha) ? 1 : -1)
    : []

  return (
    <>
      <h2 className='text-center mb-4'>Registro de Tareas</h2>
      <Row>
        <Col>
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Inicio'
            value={start}
            onChange={handleStart}
          />
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Fin'
            value={end}
            onChange={handleEnd}
          />
        </Col>
      </Row>

      {error && <States state='error' err={error} />}
      {loading && <States state='loading' />}

      <Row className='mt-4'>
        <Col>
          <div className='table-responsive' style={{ maxHeight: 'none' }}>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th scope='col'>Fecha</th>
                  <th scope='col'>Tarea</th>
                  <th scope='col'>Lavadora</th>
                  <th scope='col'>Bomba</th>
                  <th scope='col'>Hoja</th>
                  <th scope='col'>Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {data && rows.map((b, i) => (
                  <tr key={i}>
                    <td>{b.fecha}</td>
                    <td>{b.tarea.categoria} - {b.tarea.nombre}</td>
                    <td>{b.lavadora || 'Tunel'}</td>
                    <td>{b.bomba}</td>
                    <td>{b.hoja}</td>
                    <td>{b.observaciones}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  )
}
