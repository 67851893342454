import { Fragment, useState } from 'react'

import { Loading } from '../componentes/States'
import { gql, useQuery, useMutation } from '@apollo/client'
import classnames from 'classnames'

import styles from '../../css/ConfigBox.module.scss'

import { Row, Col } from 'reactstrap'

export default function Tiempos () {
  const { data, loading } = useQuery(gql`
  query{
    tunelTiempos {
      codigo 
      programa
      tiempos {
        Blanqueador
        Sanitizante
        Alcalino
        Detergente
      }
    }

    tunelConfig
  }
`)

  function quimicoTags (entries) {
    return Object.entries(entries).filter(([key]) => !key.startsWith('_'))
  }

  return (
    <>
      <h2 className='text-center'>Configuración tiempos de túnel</h2>

      {loading && <Loading />}
      {data && (
        <>
          <Row className='my-4'>
            <Col>
              <label>Concentracion de peróxido de hidrógeno (Etapa 3):</label>
              <ConfigInput tag='Concentracion Tunel Etapa 3' value={data.tunelConfig['Concentracion Tunel Etapa 3']} disabled={!data} />
              <span>ppm</span>
            </Col>
            <Col>
              <label htmlFor='alcalinidad'>Alcalinidad total (Etapa 3):</label>
              <ConfigInput tag='Alcalinidad Tunel Etapa 3' value={data.tunelConfig['Alcalinidad Tunel Etapa 3']} disabled={!data} />
              <span>ppm</span>
            </Col>
          </Row>
          <Row className='my-4'>
            <Col>
              <label>Concentracion de peróxido de hidrógeno (Etapa 8):</label>
              <ConfigInput tag='Concentracion Tunel Etapa 8' value={data.tunelConfig['Concentracion Tunel Etapa 8']} disabled={!data} />
              <span>ppm</span>
            </Col>
            <Col>
              <label htmlFor='alcalinidad'>Alcalinidad total (Etapa 8):</label>
              <ConfigInput tag='Alcalinidad Tunel Etapa 8' value={data.tunelConfig['Alcalinidad Tunel Etapa 8']} disabled={!data} />
              <span>ppm</span>
            </Col>
          </Row>
          <Row className='my-4'>
            <Col>
              <label>Concentracion de peróxido de hidrógeno (Prensa):</label>
              <ConfigInput tag='Concentracion Tunel Prensa' value={data.tunelConfig['Concentracion Tunel Prensa']} disabled={!data} />
              <span>ppm</span>
            </Col>
            <Col>
              <label htmlFor='alcalinidad'>Alcalinidad total (Prensa):</label>
              <ConfigInput tag='Alcalinidad Tunel Prensa' value={data.tunelConfig['Alcalinidad Tunel Prensa']} disabled={!data} />
              <span>ppm</span>
            </Col>
          </Row>

          <Row>
            {data.tunelTiempos.map(({ codigo, programa, tiempos: entries }) => (
              <Col key={codigo} md={4} className={styles.configBox}>
                <Row className={styles.title}>
                  <h3>({codigo}) {programa}</h3>
                </Row>
                <Row className={classnames(styles.content, 'px-5 d-none d-md-flex')}>
                  <Col md='auto'>
                    {quimicoTags(entries).map(([quimico]) => <Row key={quimico}><label>{quimico}: </label></Row>)}
                  </Col>
                  <Col className='pr-1'>
                    {quimicoTags(entries).map(values => <Row key={values[0]}><QuimicoInput values={values} codigo={codigo} /></Row>)}
                  </Col>
                  <Col md='auto' className='pr-0'>
                    {quimicoTags(entries).map(values => (
                      <Row key={values[0]} style={{ marginTop: '5px' }}>
                        <Col className='pr-0'><span>seg</span></Col>
                      </Row>
                    ))}
                  </Col>
                </Row>

                <Row className={classnames(styles.content, 'd-md-none')}>
                  <Col>
                    {quimicoTags(entries).map(values => (
                      <Fragment key={values[0]}>
                        <Row>
                          <Col>
                            <label style={{ marginTop: '15px' }}>{values[0]}: </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='pr-1'>
                            <QuimicoInput key={values[0]} values={values} codigo={codigo} />
                          </Col>
                          <Col xs='auto'>
                            <span>seg</span>
                          </Col>
                        </Row>
                      </Fragment>
                    ))}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  )
}

function QuimicoInput ({ values, codigo }) {
  const [quimico, value] = values
  const [defaultValue, setDefault] = useState(value)

  let timeout = null
  const [sendTag, { loading }] = useMutation(gql`
    mutation SendTunelTiempo ($data: TunelTiempoInput!){
      sendTunelTiempo(data: $data)
    }
  `)

  function handleChange (ev) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      sendTag({ variables: { data: { codigo: parseInt(codigo), quimico, value: parseFloat(ev.target.value) } } })
        .then(res => setDefault(res.data.setTarado))
    }, 1000)
  }

  return <input style={{ textAlign: 'right' }} disabled={loading} type='text' size='4' defaultValue={defaultValue} onChange={handleChange} />
}

function ConfigInput ({ tag, value, disabled }) {
  const [defaultValue, setDefault] = useState(value)

  let timeout = null
  const [sendTag, { loading }] = useMutation(gql`
    mutation SendTunelConfig ($data: TunelConfigInput!){
      sendTunelConfig(data: $data)
    }
  `)

  function handleChange (ev) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      sendTag({ variables: { data: { tag, value: parseFloat(ev.target.value) } } })
        .then(res => setDefault(res.data.sendTunelConfig))
    }, 1000)
  }

  return <input className='mx-2' disabled={disabled || loading} defaultValue={defaultValue} onChange={handleChange} />
}
