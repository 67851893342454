import { Container } from 'reactstrap'
import { ApolloProvider, ApolloClient, InMemoryCache, gql, ApolloLink, concat, useQuery } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import Cookies from 'js-cookie'
import { toast, ToastContainer } from 'react-toastify'

import UserContext from './UserContext'

import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'

import './css/Index.scss'

import NavSide from './js/Navbar'
import classNames from 'classnames'

import 'react-toastify/dist/ReactToastify.min.css'

import AdapterLuxon from '@material-ui/lab/AdapterLuxon'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import Login from './js/Login'
import Usuarios from './js/Usuarios'
import Index from './js/Index'
import Extractora from './js/extractora/index.js'
import Tunel from './js/tunel/index'
import Recover from './js/Recover'
import Inventario from './js/inventario'
import Factura from './js/factura'
import Densidad from './js/configDensidad'
// import NotFoundPage from './js/404'

import { useRoutes } from 'hookrouter'
import Bitacora from './js/bitacora'

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get('token')

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, createUploadLink({ uri: '/api' })),
  connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions: {
    query: {
      onError: err => {
        console.log(err)
        toast.error(err.message, { autoclose: false })
      }
    },
    mutatie: {
      onError: err => {
        console.log(err)
        toast.error(err.message, { autoclose: false })
      }
    }
  }
})

const routes = {
  '/usuarios': () => <Usuarios />,
  '/recover': () => <Recover />,
  '/densidad': () => <Densidad />,
  ...Bitacora,
  ...Inventario,
  ...Extractora,
  ...Tunel,
  ...Factura
}

function App () {
  const routeResult = useRoutes(routes)
  const { data, refetch, loading } = useQuery(gql`
    query { me { username, permitido } }
  `, { client })

  const context = { data, refetch, loading }

  const state = {
    sidebarBg: true,
    borderRadius: true,
    theme: 'light-theme',
    toggled: false,
    pinned: true
  }

  const signedIn = data !== undefined || process.env.REACT_APP_ENV === 'development'

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={context}>
        <div className={classNames({ 'sidebar-bg': state.sidebarBg, 'border-radius-on': state.borderRadius, pinned: state.pinned, toggled: state.toggled }, state.theme)}>
          <ToastContainer />
          <NavSide client={client} signedIn={signedIn} />
          <div className='page-content'>
            <Container fluid={routeResult && routeResult.props.fluid} className='pt-3'>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                {signedIn || window.location.pathname === '/recover' ? (routeResult || <Index />) : <Login />}
              </LocalizationProvider>
            </Container>
          </div>
        </div>
      </UserContext.Provider>
    </ApolloProvider>
  )
}

export default App
