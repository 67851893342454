
import { gql, useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { Col } from 'reactstrap'
import { navigate } from 'hookrouter'
import serialize from 'form-serialize'

import queryString from 'query-string'
const { key, user } = queryString.parse(window.location.search)

const validateQuery = gql`
  query Validate($user: String!, $key:String!){
    validateKey(user: $user, key: $key)
  }
`

const changeMutation = gql`
  mutation ChangePassword($user: String!, $key:String!, $password: String!){
    changePassword(user: $user, key: $key, password: $password)
  }
`

export default function Recover () {
  const { data, loading } = useQuery(validateQuery, { variables: { user, key } })
  const [changePassword] = useMutation(changeMutation)

  function handleLogin (event) {
    const serialData = serialize(event.target, { hash: true })
    serialData.user = user
    serialData.key = key
    changePassword({ variables: serialData }).then(() => {
      toast.success('Cambio finalizado!', { onClose: () => navigate('/') })
    }).catch(err => {
      console.log(err)
      toast.error(err.message, { autoclose: false })
    })

    event.preventDefault()
  }

  if ((!key || !user) || (!loading && !data.validateKey)) return 'Url invalida'

  return (
    <Col md={12} className='d-flex align-items-center justify-content-center' style={{ marginTop: '200px' }}>
      <form onSubmit={handleLogin} className=''>
        <div className='form-row' style={{ fontSize: '16px' }}>
          <div className='form-group mx-auto'>
            <label className='ml-2' htmlFor='password'>Nueva Contraseña</label>
            <input type='text' className='form-control ml-2' id='pass' name='password' required='required' />
          </div>
          <div className='form-group mx-auto'>
            <button className='btn btn-primary' type='submit'>Ingresar</button>
          </div>
        </div>
      </form>
      <div />
    </Col>
  )
}
