import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { navigate } from 'hookrouter'
import classname from 'classnames'
import { useQuery, gql } from '@apollo/client'

const querySitios = gql`
  query {
    allowed {
      name
      sitios {
        name
        tipo
        url
      }
    }
  }
`

export default function Index () {
  const { data } = useQuery(querySitios)

  return (
    <Container className='home mt-4'>
      <Row>
        {data && data.allowed.map(({ name, sitios }, i) => <Item name={name} items={sitios} key={i} />)}
      </Row>
    </Container>
  )
}

function Item ({ name, items }) {
  const [open, setOpen] = useState(false)

  return (
    <Col md={4} xs={6} className='px-4 mb-3 mx-auto'>
      <Row className='item px-2 py-4'>
        <Col md={12} className='d-flex align-items-center justify-content-center'>
          <div className='d-flex'>
            <img alt={name} src={`/img/${name}.png`} />
          </div>
        </Col>
        <Col md={12}>
          <span className={classname('mt-4 title', { open })} onClick={() => setOpen(!open)}>{name}</span>
        </Col>
        {open && (
          <Col xs='auto' className='mt-2 mx-auto'>
            {items && items.map(({ name, url }, i) => (
              <span key={i} onClick={() => navigate(url)} className='url mt-md-1'>{name}</span>
            ))}
          </Col>
        )}
      </Row>
    </Col>
  )
}
