export default function Table (props) {
  const totalisimo = props.rows ? props.rows.map(r => r.total).reduce((a, b) => a + b, 0) : 0

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          {props.columns.map(column => {
            return <th scope='col' key={column}>{column}</th>
          })}
        </tr>
      </thead>

      <tbody>
        {props.rows && props.rows.map(({ fecha, value, total }, i) => (
          <tr key={i}>
            {fecha && <td>{fecha}</td>}
            <td>{value}</td>
            <td>{total}</td>
          </tr>
        ))}
        <tr style={{ fontWeight: 'bold', backgroundColor: 'rgb(181, 230, 249)' }}>
          {props.fecha && <td />}
          <td>TOTAL: </td>
          <td>{totalisimo}</td>
        </tr>
      </tbody>
    </table>
  )
}
