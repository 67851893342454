import Bitacoras from './bitacoras'
import Bitacora from './general'
import Ingreso from './ingreso'
import IngresoCorrectiva from './ingresoCorrectiva'
import IngresoTareas from './ingresoTareas'
import IngresoGenerales from './ingresoGenerales'
import IngresoTareasGenerales from './ingresoTareasGenerales'
import Planificador from './planificador'
import Reporte from './reporte'

import Dias from './diasBox'

import { Row } from 'reactstrap'
import { gql, useQuery } from '@apollo/client'
import States from '../componentes/States'

export default {
  '/bitacora': () => <Bitacora fluid />,

  '/bitacora/ingreso': () => <Ingreso />,
  '/bitacora/ingreso/correctiva': () => <IngresoCorrectiva />,
  '/bitacora/ingreso/generales': () => <IngresoGenerales />,

  '/bitacora/ingreso/tareas': () => <IngresoTareas />,
  '/bitacora/ingreso/tareas/generales': () => <IngresoTareasGenerales />,

  '/bitacora/planificador': () => <Planificador fluid />,
  '/bitacora/registro': () => <Bitacoras />,
  '/bitacora/reporte': () => <Reporte fluid />
}

export function Pendientes ({ pendientes, dates }) {
  const query = gql`
  query Bitacora($pendientes: Boolean, $dates: [String!]) {
      tareas {
        id
        nombre
        categoria

        bitacoras(pendientes: $pendientes, dates: $dates){
          id
          lavadora
          fechaProyeccion
          bomba
        }
      }

      tareasGeneralesPendientes {
        nombre
        categoria

        bitacoras(limit: 1){
          id
          fechaProyeccion
        }
      }

      tareasTunel {
        id
        nombre
        categoria

        bitacoras(pendientes: $pendientes, dates: $dates){
          id
          fechaProyeccion
          bomba
        }
      }
  }
`
  const { data, loading, error } = useQuery(query, { variables: { pendientes, dates } })
  const dias = {}

  function filterTareas (rows, tunel = false) {
    const tareas = rows.filter(t => t.bitacoras.length > 0)
    tareas.forEach(t => {
      t.bitacoras.forEach(b => {
        const item = { tarea: t.nombre, lavadora: b.lavadora, bomba: b.bomba }
        if (tunel) item.lavadora = 'Tunel'

        if (!dias[b.fechaProyeccion]) dias[b.fechaProyeccion] = {}
        if (!dias[b.fechaProyeccion][t.categoria]) dias[b.fechaProyeccion][t.categoria] = [item]
        else dias[b.fechaProyeccion][t.categoria].push(item)
      })
    })
  }

  if (data) {
    filterTareas(data.tareas)
    filterTareas(data.tareasGeneralesPendientes)
    filterTareas(data.tareasTunel, true)
  }

  return (
    <>
      {error && <States state='error' err={error} />}
      {loading && <States state='loading' />}

      {data && (
        <Row>
          {Object.entries(dias).sort(([a], [b]) => (a > b) ? 1 : -1).map(([dia, cats]) => <Dias key={dia} dia={dia} cats={cats} />)}
        </Row>
      )}
    </>
  )
}
