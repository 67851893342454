import { Fragment, useState } from 'react'

import { Loading } from '../componentes/States'
import { gql, useQuery, useMutation } from '@apollo/client'
import classnames from 'classnames'

import styles from '../../css/ConfigBox.module.scss'

import { Row, Col } from 'reactstrap'

export default function Tarados () {
  const { data, loading } = useQuery(gql`
  query{
    tunelTarados {
        Blanqueador
        Sanitizante
        Alcalino
        Detergente
    }
  }
`)

  const quimicoTags = data && Object.entries(data.tunelTarados).filter(([key]) => !key.startsWith('_'))

  return (
    <>
      <h2 className='text-center'>Configuración tarados de túnel</h2>
      <Row className='mt-4 justify-content-center'>
        {loading && <Loading />}
        {data && (
          <Col md={4} className={styles.configBox}>
            <Row className={classnames(styles.content, 'px-5 d-none d-md-flex')}>
              <Col md='auto'>
                {quimicoTags.map(([quimico]) => <Row key={quimico}><label>{quimico}: </label></Row>)}
              </Col>
              <Col className='pr-1'>
                {quimicoTags.map(([quimico, value]) => <Row key={quimico}><QuimicoInput quimico={quimico} value={value} /></Row>)}
              </Col>
              <Col md='auto' className='pr-0'>
                {quimicoTags.map(values => (
                  <Row key={values[0]} style={{ marginTop: '5px' }}>
                    <Col className='pr-0'><span>ml/min</span></Col>
                  </Row>
                ))}
              </Col>
            </Row>

            <Row className={classnames(styles.content, 'd-md-none')}>
              <Col>
                {quimicoTags.map(([quimico, value]) => (
                  <Fragment key={quimico}>
                    <Row>
                      <Col>
                        <label style={{ marginTop: '15px' }}>{quimico}: </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='pr-1'>
                        <QuimicoInput key={quimico} value={value} />
                      </Col>
                      <Col xs='auto'>
                        <span>ml/min</span>
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  )
}

function QuimicoInput ({ quimico, value }) {
  const [defaultValue, setDefault] = useState(value)

  let timeout = null
  const [sendTag, { loading }] = useMutation(gql`
    mutation SendTunelValue ($data: TunelTaradoInput!){
      sendTunelTarado(data: $data)
    }
  `)

  function handleChange (ev) {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      sendTag({ variables: { data: { quimico, value: parseFloat(ev.target.value) } } })
        .then(res => setDefault(res.data.setTarado))
    }, 1000)
  }

  return <input style={{ textAlign: 'right' }} disabled={loading} type='text' size='4' defaultValue={defaultValue} onChange={handleChange} />
}
