import Cookies from 'js-cookie'
import { useState, useEffect } from 'react'
import { A } from 'hookrouter'
import { useQuery, gql } from '@apollo/client'
import classnames from 'classnames'
import {
  Container, Row, Col, Navbar, NavbarToggler, Collapse, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu,
  NavItem, NavLink, Nav
} from 'reactstrap'

const querySitios = gql`
  query {
    allowed {
      name
      sitios {
        name
        tipo
        url
      }
    }
  }
`

export default function NavSide (props) {
  const { data, refetch } = useQuery(querySitios)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(refetch, [props.signedIn])

  const toggle = () => setIsOpen(!isOpen)

  return (
    <header>
      <div className='banner-bg py-3'>
        <Container className='mt-0'>
          <Row>
            <Col className='py-3 ml-auto' xs='auto'>
              <img alt='vsmart_logo' src='/img/vsmart.png' height='60px' />
            </Col>
          </Row>
        </Container>
      </div>

      <Navbar className='navbar-expand-md navbar navbar-dark bg-primary sticky-top'>
        {props.signedIn && (
          <>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav navbar className='mx-auto' id='navbarAdd'>
                <NavItem className='px-md-2'>
                  <NavLink tag={A} href='/'>Home</NavLink>
                </NavItem>
                {data && data.allowed.map(({ name, sitios }, i) => {
                  return (
                    <UncontrolledDropdown key={i} nav inNavbar className={classnames({ selected: sitios.some(({ url }) => window.location.pathname === url) }, 'px-md-2')}>
                      <DropdownToggle nav caret>
                        {name}
                      </DropdownToggle>
                      <DropdownMenu className='mx-md-2'>
                        {sitios.map(({ name, url }, i) => <DropdownItem tag={A} key={i} className={classnames({ selected: window.location.pathname === url }, 'dropdown-item')} href={url}>{name}</DropdownItem>)}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )
                })}
                <NavItem className='px-md-2'>
                  <NavLink
                    href='#' onClick={() => {
                      Cookies.remove('token')
                      props.client.resetStore()
                    }}
                  >Cerrar Sesión
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        )}
      </Navbar>
    </header>
  )
}
