import { Fragment, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { gql, useLazyQuery } from '@apollo/client'
import parse, { downloadCSV, downloadPDF } from '../utils'

import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import State from '../componentes/States'
import Spacer from '../componentes/Spacer'
import { Container, Col, Row, Button } from 'reactstrap'

const query = gql`
    query Quimicos($start: String!, $end:String!, $tipo: String!, $densidad: Boolean) {
        extractoraQuimicos(start: $start, end: $end, tipo: $tipo, densidad: $densidad)
    }
`

export default function Quimicos () {
  const [start, handleStart] = useState(DateTime.now())
  const [end, handleEnd] = useState(DateTime.now())
  const [tipo, setModo] = useState('registro')
  const [mult, setMult] = useState(1)
  const [densidad, setDensidad] = useState(false)
  const [loadData, { data, loading }] = useLazyQuery(query)

  function submit () {
    if (start && end) loadData({ variables: { start, end, tipo, densidad } })
  }

  useEffect(submit, [start, end, tipo, densidad, loadData])

  function printPDF () {
    downloadPDF(`Reporte extractoras químicos ${[start.toLocaleString(DateTime.DATE_SHORT), end.toLocaleString(DateTime.DATE_SHORT)].join(' - ')}`, document.getElementById('rootPDF'))
  }

  function printCSV () {
    downloadCSV(`Reporte extractoras químicos ${[start.toLocaleString(DateTime.DATE_SHORT), end.toLocaleString(DateTime.DATE_SHORT)].join(' - ')}`, document.getElementById('rootPDF'))
  }

  return (
    <Container>
      <Row className='justify-content-center'><h2>Reporte Extractoras Químicos</h2></Row>
      <Row className='mt-5 d-flex justify-content-center'>
        <Col xs='auto' className='my-4 my-md-0 mt-md-auto  justify-content-md-end'>
          <Button onClick={printPDF} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar PDF</Button>
          <Button onClick={printCSV} style={{ height: '35px', backgroundColor: '#4aa0d1' }} className='mx-2 mt-auto'><i className='fa fa-download px-1' aria-hidden='true' />Exportar CSV</Button>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Inicio'
            value={start}
            onChange={handleStart}
          />
          <DatePicker
            renderInput={(props) => <TextField className='w-50' {...props} />}
            label='Fin'
            value={end}
            onChange={handleEnd}
          />
        </Col>
        <Col md='auto' className='py-2 py-md-0'>
          <h5>Seleccionar reporte:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio1' value='registro' defaultChecked onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio1'>Total</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio2' value='diario' onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio2'>Diario</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio3' value='mensual' onChange={ev => setModo(ev.target.value)} />
            <label className='form-check-label' htmlFor='inlineRadio3'>Mensual</label>
          </div>
        </Col>
        <Col md='auto' className='py-2 py-md-0'>
          <h5>Seleccionar medida:</h5>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(1); setDensidad(false) }} defaultChecked />
            <label className='form-check-label'>Mililitros (mL)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(0.001); setDensidad(false) }} />
            <label className='form-check-label'>Litros (L)</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio' name='medida' onChange={() => { setMult(1); setDensidad(true) }} />
            <label className='form-check-label'>Kilogramos (Kg)</label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} id='rootPDF'>
          <State state={loading ? 'loading' : ''} />
          {data && (
            data.extractoraQuimicos.tipo === 'registro'
              ? <ReporteTotal data={data.extractoraQuimicos} mult={parseFloat(mult)} dates={[start, end]} />
              : <ReporteFecha data={data.extractoraQuimicos} mult={parseFloat(mult)} dates={[start, end]} />
          )}
        </Col>
      </Row>
    </Container>
  )
}

function mergeObj (data) {
  // Taken from https://dev.to/ramonak/javascript-how-to-merge-multiple-objects-with-sum-of-values-43fd
  const result = {}

  data.forEach(basket => {
    for (const [key, value] of Object.entries(basket)) {
      if (result[key]) result[key] += value
      else result[key] = value
    }
  })
  return result
};

function ReporteTotal (props) {
  const { data, mult, dates } = props

  if (Object.values(data.quimico).length === 0) return null
  const progs = Object.keys(data.programa)

  return (
    <>
      <Row className='mt-4'>
        <Col>
          <div className='table-responsive'>
            <table id='ReporteKilogramos' className='table table-striped'>
              <thead>
                <tr>
                  {Object.keys(data.quimico).map((q, i) => <th scope='col' key={i}>{q}</th>)}
                  <th scope='col'>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.values(data.quimico).map((q, i) => <td key={i}>{parse(q, mult)}</td>)}
                  <td>{parse(Object.values(data.quimico).reduce((a, b) => a + b), mult)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col md={12}><h4 style={{ textAlign: 'center' }}>Químico consumido {dates[0].toFormat('yyyy-MM-dd')} - {dates[1].toFormat('yyyy-MM-dd')}</h4></Col>
        <Col>
          <div className='table-responsive'>
            <table id='ReporteKilogramos' className='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>Lavadora</th>
                  <th scope='col'>Químico</th>
                  {progs.map(prog => <th scope='col' key={prog}>{prog.replace('_', ' ')}</th>)}
                  <th scope='col'>Total Químico</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.data).map(([lav, lavData]) => {
                  const progSum = mergeObj(Object.values(lavData))
                  return (
                    <Fragment key={lav}>
                      {Object.entries(lavData).map(([quimico, quimicoData], quimicoIndex) =>
                        <tr key={quimico}>
                          <td>{quimicoIndex === 0 && lav}</td>
                          <td style={{ textTransform: 'capitalize', fontWeight: 'bold', fontStyle: 'italic' }}>{quimico}</td>
                          {progs.map(prog => <td key={prog}>{parse(quimicoData[prog] || null, mult)}</td>)}
                          <td>{parse(Object.values(quimicoData).reduce((a, b) => a + b), mult)}</td>
                        </tr>
                      )}
                      <tr style={{ fontStyle: 'italic' }}>
                        <td>&nbsp;</td>
                        <td>TOTAL {lav}: </td>
                        {progs.map(prog => <td key={prog}>{parse(progSum[prog] || null, mult)}</td>)}
                        <td>{parse(Object.values(progSum).reduce((a, b) => a + b), mult)}</td>
                      </tr>
                      <Spacer />
                    </Fragment>
                  )
                })}

                <tr style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                  <td>&nbsp;</td>
                  <td>TOTAL POR PROGRAMA: </td>
                  {progs.map(prog => <td key={prog}>{parse(data.programa[prog] || null, mult)}</td>)}
                  <td>{parse(Object.values(data.programa).reduce((a, b) => a + b), mult)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  )
}

function ReporteFecha (props) {
  const { data, mult, dates } = props
  const { progs } = data

  if (progs.length === 0) return null

  return (
    <Row className='mt-5'>
      <Col md={12}><h4 style={{ textAlign: 'center' }}>Químico consumido {dates[0].toFormat('yyyy-MM-dd')} - {dates[1].toFormat('yyyy-MM-dd')}</h4></Col>
      <Col>
        <div className='table-responsive'>
          <table id='ReporteKilogramos' className='table table-striped'>
            <thead>
              <tr>
                <th scope='col'>Fecha</th>
                <th scope='col'>Lavadora</th>
                <th scope='col'>Químico</th>
                {progs.map(prog => <th scope='col' key={prog}>{prog.replace('_', ' ')}</th>)}
                <th scope='col'>Total Químico</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(data.data).map(([fecha, fechaData], fechaIndex) =>
                <Fragment key={fecha}>
                  {fechaIndex > 0 && <Spacer />}
                  {Object.entries(fechaData).map(([lav, lavData], lavIndex) => {
                    const progSum = mergeObj(Object.values(lavData))
                    return (
                      <Fragment key={lav}>
                        {lavIndex > 0 && <Spacer />}
                        {Object.entries(lavData).map(([quimico, quimicoData], quimicoIndex) =>
                          <tr key={quimico}>
                            <td>{quimicoIndex === 0 && fecha}</td>
                            <td>{quimicoIndex === 0 && lav}</td>
                            <td style={{ textTransform: 'capitalize', fontWeight: 'bold', fontStyle: 'italic' }}>{quimico}</td>
                            {progs.map(prog => <td key={prog}>{parse(quimicoData[prog] || null, mult)}</td>)}
                            <td>{parse(Object.values(quimicoData).reduce((a, b) => a + b), mult)}</td>
                          </tr>
                        )}
                        <tr style={{ fontStyle: 'italic' }}>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>TOTAL {lav}: </td>
                          {progs.map(prog => <td key={prog}>{parse(progSum[prog] || null, mult)}</td>)}
                          <td>{parse(Object.values(progSum).reduce((a, b) => a + b), mult)}</td>
                        </tr>
                      </Fragment>
                    )
                  })}

                  <tr style={{ fontWeight: 'bold', backgroundColor: '#b5e6f9' }}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>TOTAL {fecha}</td>
                    {progs.map(prog => <td key={prog}>{parse(data.fecha[fecha][prog], mult)}</td>)}
                    <td>{parse(Object.values(data.fecha[fecha]).reduce((a, b) => a + b), mult)}</td>
                  </tr>
                </Fragment>
              )}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  )
}
