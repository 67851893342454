/* global FormData */
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'

import classnames from 'classnames'

const mutations = {
  'Mantenimiento Preventivo': {
    Tunel: gql`mutation create($nombre: String!, $intervaloSemanas: String!, $categoria: String!) {
      createTareaTunel(nombre: $nombre, intervaloSemanas: $intervaloSemanas, categoria: $categoria){
        id
      }
    }`,
    Lavadoras: gql`mutation create($nombre: String!, $intervaloSemanas: String!, $categoria: String!) {
      createTarea(nombre: $nombre, intervaloSemanas: $intervaloSemanas, categoria: $categoria){
        id
      }
    }`
  },
  'Mantenimiento Correctivo': {
    Tunel: gql`mutation create($nombre: String!) {
      createTareaCorrectivaTunel(nombre: $nombre){
        id
      }
    }`,
    Lavadoras: gql`mutation create($nombre: String!) {
      createTareaCorrectiva(nombre: $nombre){
        id
      }
    }`
  }
}

export default function Ingreso () {
  const query = gql`
  query Bitacora {
    categorias {
      nombre
    }

    categoriasTunel {
      nombre
    }
  }
`
  const { data } = useQuery(query)
  const [tipo, setTipo] = useState('Mantenimiento Preventivo')
  const [lav, setLav] = useState('Lavadoras')
  const [mutation, { loading }] = useMutation(mutations[tipo][lav])

  function handleSubmit (event) {
    event.preventDefault()
    const variables = Object.fromEntries(new FormData(event.target))

    mutation({ variables })
      .then(() => toast.success('Tarea agregada!'))
      .catch(err => {
        console.log(err)
        toast.error('Error al guardar tarea')
      })
  }

  const cats = data && (lav === 'Tunel' ? data.categoriasTunel : data.categorias)

  return (
    <>
      <h2 className='text-center mb-4'>Configuracion de Tareas Lavadoras/Tunel</h2>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col className=''>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='tipo'>Tipo</Label>
                      <Input type='select' name='tipo' onChange={ev => setTipo(ev.target.value)}>
                        <option value='Mantenimiento Preventivo'>Mantenimiento Preventivo</option>
                        <option value='Mantenimiento Correctivo'>Mantenimiento Correctivo</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='lavadora'>Lavadoras o Tunel</Label>
                      <Input type='select' name='lavadora' onChange={ev => setLav(ev.target.value)}>
                        <option value='Lavadoras'>Lavadoras</option>
                        <option value='Tunel'>Tunel</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                {tipo === 'Mantenimiento Preventivo' && (
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='categoria'>Categoria</Label>
                        <Input type='select' name='categoria'>
                          {data && cats.map(c => <option key={c.nombre} value={c.nombre}>{c.nombre}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='nombre'>Nombre de tarea</Label>
                      <Input type='text' name='nombre' />
                    </FormGroup>
                  </Col>
                </Row>

                {tipo === 'Mantenimiento Preventivo' && (
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='intervaloSemanas'>Intervalo de semanas</Label>
                        <Input type='number' name='intervaloSemanas' required />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <button type='submit' className={classnames('btn btn-primary', { disabled: loading })}>{loading ? 'Enviando..' : 'Enviar'}</button>
          </Form>
        </Col>
      </Row>
    </>
  )
}
