import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import { Col, Row } from 'reactstrap'
import { Loading } from '../componentes/States'

import Table from '../componentes/Table'

const query = gql`
    query Reporte($start: String!, $end: String!, $modo: String!) {
        extractoraReporte(start: $start, end: $end, modo: $modo, tipo: "suciedad") {
          value
          fecha
          total
        }
    }
`

export default function Suciedad () {
  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())
  const [modo, setModo] = useState('registro')
  const [loadData, { data, loading }] = useLazyQuery(query)

  function handleModo (ev) {
    setModo(ev.target.value)
  }

  useEffect(handleData, [modo, start, end])

  function handleData () {
    if (start && end) loadData({ variables: { start, end, modo } })
  }

  return (
    <div className='container'>
      <h2 className='text-center'>Reporte extractoras KGs (por suciedad)</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <div className='form-group form-row'>
              <div className='col'>
                <DatePicker
                  renderInput={(props) => <TextField className='w-50' {...props} />}
                  label='Inicio'
                  value={start}
                  onChange={handleStart}
                />
                <DatePicker
                  renderInput={(props) => <TextField className='w-50' {...props} />}
                  label='Fin'
                  value={end}
                  onChange={handleEnd}
                />
              </div>

              <Col md='auto'>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio1' value='registro' defaultChecked onChange={handleModo} />
                  <label className='form-check-label' htmlFor='inlineRadio1'>Total</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio2' value='diario' onChange={handleModo} />
                  <label className='form-check-label' htmlFor='inlineRadio2'>Diario</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input inputChange' type='radio' name='tipo' id='inlineRadio3' value='mensual' onChange={handleModo} />
                  <label className='form-check-label' htmlFor='inlineRadio3'>Mensual</label>
                </div>
              </Col>
            </div>

            {loading && (
              <Row>
                <Col>
                  <Loading />
                </Col>
              </Row>
            )}

            <div id='root' className='table-responsive'>
              <Table
                fecha={modo !== 'registro'}
                columns={modo === 'registro' ? ['Suciedad', 'Total'] : ['Fecha', 'Suciedad', 'Total']}
                rows={data && data.extractoraReporte}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
