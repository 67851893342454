import { gql, useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import serialize from 'form-serialize'
import 'react-toastify/dist/ReactToastify.min.css'

const siteMutation = gql`
  mutation UpdateSite($key: String!, $value: Boolean!, $url: String!) {
    updateSite(key: $key, value: $value, url: $url) {username}
  }
`

const userUpdateMutation = gql`
  mutation UpdateUser($key: String!, $username: String, $email: String, $enabled: Boolean, $password: String) {
    updateUser(key: $key, username: $username, email:$email, enabled: $enabled, password: $password) {username}
  }
`

const userMutation = gql`
  mutation CreateUser($username: String!, $email: String!, $password: String!) {
    createUser(username: $username, email: $email, password: $password) {username}
  }
`

const recoverMutation = gql`
  mutation RecoverPassword($username: String!) {
    recoverPassword(username: $username)
  }
`

function onError (err) {
  console.log(err)
  toast.error(err.message, { autoclose: false })
}

export default function Usuarios () {
  const { data } = useQuery(gql`
    query Users{
      users{
        email
        username
        enabled
        permitido
      }

      sitios {
        name
        sitios {
          name
          url
          tipo
        }
      }
    }
  `)
  const [updateSite] = useMutation(siteMutation, { onError, onCompleted: () => toast.success('Usuario actualizado!') })
  const [updateUser] = useMutation(userUpdateMutation, { onError, onCompleted: () => toast.success('Usuario actualizado!') })
  const [createUser] = useMutation(userMutation, { onError, onCompleted: () => toast.success('Usuario agregado!') })
  const [recoverPassword] = useMutation(recoverMutation, { onError, onCompleted: () => toast.success('Correo enviado!') })

  function handleCreate (ev) {
    ev.persist()
    ev.preventDefault()

    createUser({ variables: serialize(ev.target, { hash: true }) })
  }

  function handleUpdate (ev) {
    ev.persist()
    ev.preventDefault()

    updateUser({ variables: serialize(ev.target, { hash: true }) })
  }

  function handleRecover (ev) {
    ev.persist()
    ev.preventDefault()

    recoverPassword({ variables: serialize(ev.target, { hash: true }) })
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-6 border'>
          <form onSubmit={handleCreate}>
            <div className='form-group form-row mt-3'>
              <h3>Agregar Usuario</h3>
            </div>
            <div className='form-group form-row'>
              <div className='form-group'>
                <label htmlFor='user'>Nombre de usuario</label>
                <input type='text' className='form-control' id='user' name='username' required='required' />
              </div>
              <div className='form-group'>
                <label className='' htmlFor='pass'>Email</label>
                <input type='text' className='form-control' id='email' name='email' required='required' />
              </div>
            </div>
            <div className='form-group form-row'>
              <div className='form-group'>
                <label className='' htmlFor='pass'>Contraseña</label>
                <input type='password' className='form-control' id='pass' name='password' required='required' />
              </div>
            </div>
            <button className='btn btn-primary' type='submit'>Agregar Usuario</button>
          </form>
        </div>
        <div className='col-md-6 border'>
          <form onSubmit={handleUpdate}>
            <div className='form-group form-row pt-3'>
              <h3>Modificar Usuario</h3>
            </div>
            <div className='form-group form-row'>
              <div className='form-group'>
                <label htmlFor='user'>Usuario a modificar</label>
                <select name='key' className='form-control  ' required='required'>
                  {data && data.users.map(({ username }) => <option key={username} value={username}>{username}</option>)}
                </select>
              </div>
              <div className='form-group'>
                <label className='' htmlFor='pass'>Nuevo nombre de ususario</label>
                <input type='password' className='form-control ' name='username' />
              </div>
              <div className='form-group'>
                <label className='' htmlFor='pass'>Nueva Contraseña</label>
                <input type='password' className='form-control ' name='password' />
              </div>
              <div className='form-group'>
                <label className='' htmlFor='pass'>Nuevo Email</label>
                <input type='text' className='form-control ' id='email' name='email' />
              </div>
            </div>
            <button className='btn btn-primary' type='submit'>Guardar Cambios</button>
          </form>
        </div>
      </div>
      <hr />

      <div className='row'>
        <div className='col border'>
          <form onSubmit={handleRecover}>
            <div className='form-group form-row pt-3'>
              <h3>Recuperar Contraseña</h3>
            </div>
            <div className='form-group form-row'>
              <div className='form-group'>
                <label htmlFor='username'>Usuario</label>
                <select name='username' className='form-control  ' required='required'>
                  {data && data.users.map(({ username }) => <option key={username} value={username}>{username}</option>)}
                </select>
              </div>
            </div>
            <button className='btn btn-primary' type='submit'>Enviar Email</button>
          </form>
        </div>
      </div>
      <hr />

      <div className='row'>
        <div className='col py-2' style={{ fontSize: '20px', captionSide: 'top', color: 'white', textAlign: 'center', backgroundColor: '#0562a6' }}>
          Accesos de usuarios
        </div>

      </div>

      <div className='row' style={{ marginBottom: '100px' }}>
        <div className='col overflow-auto px-0' style={{ maxHeight: '350px' }}>
          <table className='table table-striped border'>

            <thead>
              <tr>
                <th scope='col'>Nombre usuario</th>
                <th scope='col'>Activo</th>
                {data && data.sitios.map(({ name, sitios }) => sitios.map(({ name, tipo, url }) => <th key={url} scope='col'>{['Facturación'].includes(tipo) && `${tipo} - `}{name}</th>))}
              </tr>
            </thead>

            <tbody>
              {data && data.users.map(({ username, permitido, enabled }) => (
                <tr key={username}>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{username}</td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><input type='checkbox' defaultChecked={enabled} onChange={e => updateUser({ variables: { enabled: e.target.checked, key: username } })} /></td>
                  {data && data.sitios.map(({ name, sitios }) => sitios.map(({ name, tipo, url }) => <td key={url} style={{ textAlign: 'center', verticalAlign: 'middle' }}><input type='checkbox' defaultChecked={permitido.includes(url)} onChange={e => updateSite({ variables: { value: e.target.checked, key: username, url } })} /></td>))}
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}
