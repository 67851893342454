import React from 'react'
import Registro from './registro'
import Upload from './upload'
import Kilogramos from './registroKilogramos'
import Quimicos from './registroQuimicos'
import Tiempos from './tiempos'
import Tarados from './tarados'
import Diagrama from './diagrama'
import Legado from './legado'

export default {
  '/tunel': () => <Registro />,
  '/tunel/diagrama': () => <Diagrama />,
  '/tunel/upload': () => <Upload />,
  '/tunel/programa': () => <Kilogramos />,
  '/tunel/quimicos': () => <Quimicos />,
  '/tunel/tarado': () => <Tarados />,
  '/tunel/tiempos': () => <Tiempos />,
  '/tunel/legado': () => <Legado />
}
