import { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import serialize from 'form-serialize'
import { toast } from 'react-toastify'

const mutation = gql`
    mutation Ingreso($data: InputExtractoraIngreso!) {
        extractoraIngreso(data: $data){
          grado
        }
    }
`

export default function Ingreso () {
  const [rowCount, setRowCount] = useState([0])
  const [obsCheck, setObsCheck] = useState(false)
  const [lav, setLav] = useState(null)

  const { data: dataTags } = useQuery(gql`
    query {
      extractoraTags {
        lav
        programas {
          nombre: programa
        }
      }
    }
  `)
  const [send] = useMutation(mutation)

  useEffect(() => {
    if (dataTags) setLav(dataTags.extractoraTags[0].lav)
  }, [dataTags, setLav])

  function submit (ev) {
    const data = serialize(ev.target, { hash: true })
    let checkEmpty = true

    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string' || value instanceof String) {
        if (!value.trim()) checkEmpty = false
      }
    })

    if (checkEmpty) {
      send({ variables: { data } })
        .then(function (response) {
          toast.success('Registros Actualizados!', { onClose: () => window.location.reload(true) })
        })
        .catch(function (error) {
          console.log(error)
          toast.error('Error al actualizar registros')
        })
    }

    ev.preventDefault()
  }

  return (
    <div className='container'>
      <h2 className='text-center'>Ingresar lavadas</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <form id='addUser' className='form-horizontal' onSubmit={submit}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group form-row'>
                    <label htmlFor='fecha' className='col-md-1'>Fecha:</label>
                    <div className='col-md-3'>
                      <input
                        type='date' name='fecha' className='form-control' id='fecha'
                        required='required'
                      />
                    </div>
                    <label htmlFor='lavadora' className='col-md-1'>Lavadora:</label>
                    <div className='col-md-3'>
                      <select className='custom-select form-control' name='lavadora' id='lavadora' required='required' onChange={ev => setLav(ev.target.value)}>
                        {dataTags && dataTags.extractoraTags.map(l => <option key={l.lav} value={l.lav}>{l.lav}</option>)}
                      </select>
                    </div>

                    <label htmlFor='turno' className='col-md-1'>Turno:</label>
                    <div className='col-md-3'>
                      <input
                        type='number' name='turno' className='form-control' id='turno'
                        required='required' min={1} max={3}
                      />
                    </div>

                  </div>

                  <div className='form-group form-row'>
                    <label htmlFor='responsable' className='col-md-2'>Responsable:</label>
                    <div className='col-md-3'>
                      <input
                        type='text' name='responsable' className='form-control' id='responsable'
                        required='required'
                      />
                    </div>
                    <div className='col-md-2' />
                    <label htmlFor='supervisor' className='col-md-2'>Supervisor:</label>
                    <div className='col-md-3'>
                      <input
                        type='text' name='supervisor' className='form-control' id='supervisor'
                        required='required'
                      />
                    </div>
                  </div>

                  <div className='form-group form-row'>
                    <div className='col-auto d-flex'>
                      <input
                        type='checkbox' style={{ height: '15px', width: '15px' }}
                        className='form-control align-self-center' id='checkObservaciones'
                        checked={obsCheck} onChange={ev => setObsCheck(ev.target.checked)}
                      />
                    </div>
                    <label htmlFor='observaciones' className='col-auto'>Observaciones:</label>
                    <div className='col'>
                      <input
                        type='text' name='observaciones' maxLength='100' className='form-control'
                        id='observaciones' disabled={!obsCheck}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <table className='table-fill'>
                      <thead>
                        <tr>
                          <th className='text-left'>Tipo Suciedad</th>
                          <th className='text-left'>Grado Suciedad</th>
                          <th className='text-left'>Programa</th>
                          <th className='text-left'>Tipo de Prenda</th>
                          <th className='text-left'>Centro Hospitalario</th>
                          <th className='text-left'>Reproceso</th>
                          <th className='text-left'>Kg lavados</th>
                        </tr>
                      </thead>
                      <tbody className='table-hover' id='rows'>
                        {rowCount.map(key => (
                          key !== undefined && (
                            <tr key={key}>
                              <td className='text-left'>
                                <select defaultValue='na' name={`rows[${key}][suciedad]`} className='form-control' required='required'>
                                  <option value='na'>Escoge Uno:</option>
                                  <option value='Suciedad Liviana'>Suciedad Liviana</option>
                                  <option value='Suciedad Regular'>Suciedad Regular</option>
                                  <option value='Suciedad Pesada'>Suciedad Pesada</option>
                                  <option value='Suciedad Contaminada'>Suciedad Contaminada</option>
                                  <option value='Suciedad muy Pesada'>Suciedad Nueva</option>
                                </select>
                              </td>
                              <td className='text-left'><input type='text' name={`rows[${key}][grado]`} className='form-control' required='required' /></td>

                              <td className='text-left'>
                                <select defaultValue='na' name={`rows[${key}][programa]`} className='form-control' required='required'>
                                  <option value='na'>Escoge Uno:</option>
                                  {lav && dataTags.extractoraTags.find(e => e.lav === lav).programas.map((p, i) => <option key={i} value={p.nombre}>{p.nombre}</option>)}
                                </select>
                              </td>

                              <td className='text-left'><input type='text' name={`rows[${key}][prenda]`} className='form-control' required='required' /></td>
                              <td className='text-left'><input type='text' name={`rows[${key}][hospital]`} className='form-control' required='required' /></td>
                              <td className='text-left'>
                                <select defaultValue='No' name={`rows[${key}][reproceso]`} className='form-control' required='required'>
                                  <option value='No'>No</option>
                                  <option value='Si'>Si</option>
                                </select>
                              </td>
                              <td className='text-left'><input type='number' max={300} name={`rows[${key}][lavados]`} className='form-control' required='required' /></td>
                              <td>
                                <input
                                  className='btn btn-primary' type='button' onClick={() => {
                                    const newRows = [...rowCount]
                                    newRows[key] = undefined

                                    setRowCount(newRows)
                                  }} value='Eliminar'
                                />
                              </td>
                            </tr>
                          )))}
                      </tbody>
                    </table>
                    <br />
                    <input className='btn btn-primary pull-right ' type='submit' value='Enviar' />
                    <input
                      className='btn btn-primary pull-right ' type='button' onClick={() => setRowCount([...rowCount, rowCount.length])}
                      value='Agregar Registro'
                    />
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
