import { useState, useEffect } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import Chart from 'react-apexcharts'
import es from 'apexcharts/dist/locales/es.json'
import { DateTime } from 'luxon'

import { Col, Row } from 'reactstrap'
import DatePicker from '../componentes/DatePicker'
import State from '../componentes/States'

const query = gql`
  query Factura($start: String!, $end: String!) {
    res: reporteFactura(start:$start, end:$end)
  }
`
const querySuciedad = gql`
  query Suciedad {
    suciedades
    tunelSuciedades
  }
`

const pallete = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#D10000', '#CC5803', '#7C0B2B']
const options = {
  chart: { type: 'bar', locales: [es], defaultLocale: 'es' },
  colors: pallete,

  plotOptions: {
    bar: {
      endingShape: 'rounded',
      columnWidth: '60%',
      distributed: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  dataLabels: {
    enabled: true
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: { categories: [/* fill on render method */], axisTicks: { show: true, height: 5 } },
  fill: {
    opacity: 1
  },
  tooltip: {
    followCursor: true,
    shared: true,
    y: {
      formatter: function (val) {
        if (val) return `${val} kilogramos`
      }
    }
  }
}

export default function Content () {
  const [dates, setDates] = useState()
  const [loadData, { err, data, loading }] = useLazyQuery(query)
  const { err: errSuciedad, data: dataSuciedad, loading: loadingSuciedad } = useQuery(querySuciedad)

  function handleData () {
    if (dates) loadData({ variables: { start: dates[0], end: dates[1] } })
  }

  useEffect(handleData, [dates])

  return (
    <>
      <Row className='text-center justify-content-center'><h2>Gráfico Facturación</h2></Row>
      <Row className='justify-content-center'>
        <Col md='auto'><DatePicker simple handleDate={strings => setDates(strings.map(e => DateTime.fromJSDate(e).toFormat('yyyy-MM-dd')))} id='datepick' /></Col>
      </Row>
      <Row className='mt-4 justify-content-center'>
        {dataSuciedad && Object.entries(dataSuciedad.tunelSuciedades).map(([suciedad, codigos]) => (
          <Col md='auto' key={suciedad}>
            <span style={{ color: '#4158d4' }} className='mr-1'>Tunel {suciedad}</span><span>= {codigos.join(' - ')}</span>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={12}>
          <State state={(err || errSuciedad) ? 'error' : ((loadingSuciedad || loading) ? 'loading' : '')} />
          {data && <Grafico suciedad={dataSuciedad} data={data} />}
        </Col>
      </Row>
    </>
  )
}

function Grafico (props) {
  const labelSet = [...props.suciedad.suciedades.filter(i => !!i), 'TOTAL']
  const data = []

  Object.entries(props.data.res.lavs).forEach(([lav, lavValues]) => {
    Object.entries(lavValues.suciedad).forEach(([suciedad, suciedadValue]) => {
      const progIndex = labelSet.indexOf(suciedad)
      if (!data[progIndex]) data[progIndex] = suciedadValue
      else data[progIndex] += suciedadValue
    })
  })

  options.xaxis.categories = labelSet
  data.push(data.reduce((a, b) => a + b))

  return (
    <Chart options={options} series={[{ data }]} type='bar' height={550} />
  )
}
