import { Col } from 'reactstrap'
import { useState } from 'react'
import classnames from 'classnames'

export default function Dias ({ dia, cats }) {
  const [open, setOpen] = useState(false)

  return (
    <Col md={4} className='my-2'>
      <div className='list-group list-group-root'>

        <a className='list-group-item list-group-item-action' onClick={() => setOpen(!open)}>
          <i className={classnames('fa', open ? 'fa-caret-down' : 'fa-caret-right')} />{dia}
        </a>

        {Object.entries(cats).map(([cat, tareas]) => <Cat key={cat} collapse={!open} cat={cat} tareas={tareas} />)}
      </div>
    </Col>
  )
}

function Cat ({ collapse, cat, tareas }) {
  const [open, setOpen] = useState(true)

  return (
    <div className={classnames('list-groupe', { collapse })}>

      <a className='list-group-item list-group-item-action' onClick={() => setOpen(!open)}>
        <i className={classnames('fa ml-2', open ? 'fa-caret-down' : 'fa-caret-right')} />{cat}
      </a>
      <div className={classnames('list-group', { collapse: !open })}>
        {tareas.map((t, i) => <a key={i} className='pl-5 list-group-item list-group-item-action'>{t.tarea} - {t.lavadora} - {t.bomba}</a>)}
      </div>

    </div>
  )
}
