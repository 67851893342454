import { useRef } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'

const mutation = gql`
  mutation upload($data: String!) {
    tunelUpload(data: $data)
  }
`

export default function Upload () {
  const fileRef = useRef()
  const [send] = useMutation(mutation, { onCompleted: () => toast.success('Actualización Completada!') })

  return (
    <div className='container'>
      <h2 className='text-center'>Actualizar registro de túnel</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <div className='form-group form-row'>
              <label htmlFor='start' className='col-auto'>Archivo túnel: </label>
              <div className='col'>
                <input name='file' type='file' className='form-control inputChange' id='start' ref={fileRef} />
              </div>
              <div className='col-auto'>
                <Button color='primary' onClick={async () => send({ variables: { data: await fileRef.current.files[0].text() } })}>Subir</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
