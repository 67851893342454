import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import DatePicker from '@material-ui/lab/DatePicker'
import { Col, Row } from 'reactstrap'
import { Loading } from '../componentes/States'

const cols = ['fecha', 'programa', 'ph', 'alcalinidad', 'h202']

const query = gql`
    query Registro($start: String!, $end:String!) {
        tunelLegado(start: $start, end: $end) {
          fecha
          programa
          ph
          alcalinidad
          h202
        }
    }
`

export default function Registro () {
  const [start, handleStart] = useState(new Date())
  const [end, handleEnd] = useState(new Date())
  const [loadData, { data, loading }] = useLazyQuery(query)

  function submit () {
    if (start && end) {
      loadData({
        variables: { start, end }
      })
    }
  }

  useEffect(submit, [start, end, loadData])

  return (
    <div className='container'>
      <h2 className='text-center'>Archivos históricos de PH 2018-2020</h2>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='well well-sm'>
            <form id='addUser' className='form-horizontal'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group form-row'>
                    <div className='col'>
                      <DatePicker
                        renderInput={(props) => <TextField className='w-50' {...props} />}
                        label='Inicio'
                        value={start}
                        onChange={handleStart}
                      />
                      <DatePicker
                        renderInput={(props) => <TextField className='w-50' {...props} />}
                        label='Fin'
                        value={end}
                        onChange={handleEnd}
                      />
                    </div>
                  </div>

                  {loading && (
                    <Row>
                      <Col>
                        <Loading />
                      </Col>
                    </Row>
                  )}

                  <div className='form-group table-responsive'>
                    <table className='table' id='table'>
                      <thead>
                        <tr>
                          <th className='text-left' scope='col'>Fecha</th>
                          <th className='text-left' scope='col'>Programa</th>
                          <th className='text-left' scope='col'>pH</th>
                          <th className='text-left' scope='col'>Alcalinidad ppm</th>
                          <th className='text-left' scope='col'>H2O2 ppm</th>
                        </tr>
                      </thead>
                      <tbody id='rows'>
                        {data && data.tunelLegado.map((row, i) => (
                          <tr key={i}>
                            {cols.map(col => <td key={col} className={col === 'hospital' ? 'bold' : undefined}>{row[col] || ''}</td>)}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
